import { Button, Input, Modal, Descriptions } from "antd";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useEffect, useCallback, useState } from "react";
import h from "../helper";
import { Prompt } from "react-router-dom";
import { isBool } from "../../../../../util/helper";
import { useDispatch, useSelector } from "react-redux";
import { SelectOption } from "../../../../../components/form";
import { RootState } from "../../../../../app/reducer";
import { setPaymentState } from "../../../../../slices/store";
import { renderHalfSwitch } from "../_components/payment-functions";
import { renderHalfTxtInput, renderHalfTxtSelec } from "./payment-functions";
import PPCPModal from "./ppcp-modal";
import { getOnBoardLink, generatePPCP3rdPartyToken, revokePPCP3rdPartyToken } from "./../../../../../services/store";
import aH from "./../../../../admin-users/helper";
import { setIsOnboardLoaded } from "../../../../../slices/ppcpOnboard";
import config from "config";

const currencySymbols: { [key: string]: string } = config.CURRENCY_SYMBOL;

function App(): JSX.Element {
    const intl = useIntl();
    const dispatch = useDispatch();
    const paymentOptions = useSelector((state: RootState) => state.store?.storePaymentOptions);
    const storeCurrency =
        useSelector((state: RootState) => state.store?.storeCurrencyAndPricePlan?.store_currency) ?? "CAD";
    const [isPartnerClicked, setIsPartnerClicked] = useState(false);
    const [editingRule, setEditingRule] = useState<any>(null);
    const isSuperManagerUser = () => {
        return aH.isSuperManagerUser(currUser);
    };

    const {
        ppcp_enabled,
        ppcp_merchant_id,
        ppcp_txn_type,
        ppcp_merch_name,
        ppcp_merch_phone,
        ppcp_payment_google_pay,
        ppcp_payment_apple_pay,
        ppcp_payment_paypal,
        ppcp_partner_fee,
        ppcp_mode,
        ppcp_contingency_3d_secure,
        ppcp_thirdparty_token,
        ppcp_thirdparty_token_created_at,
    } = paymentOptions;
    const currUser = useSelector((state: RootState) => state.user);

    const payAction: SelectOption[] = [
        { value: "Authorization", label: intl.formatMessage({ id: "authorization" }) },
        { value: "Sale", label: intl.formatMessage({ id: "sale" }) },
    ];

    const modeOptions: SelectOption[] = [
        { value: "sandbox", label: "Sandbox" },
        { value: "production", label: "Production" },
    ];

    const setState = useCallback(
        (name: string, value: any) => {
            return dispatch(setPaymentState({ name, value }));
        },
        [dispatch]
    );
    const location = useLocation();
    const [merchantIdInPayPal, setMerchantIdInPayPal] = useState("");

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const merchantId = searchParams.get("merchantIdInPayPal");

        if (merchantId != null) {
            dispatch(setIsOnboardLoaded(1));
            setMerchantIdInPayPal(merchantId);
            dispatch(setPaymentState({ name: "ppcp_enabled", value: true }));
            dispatch(setPaymentState({ name: "ppcp_merchant_id", value: merchantId }));
            dispatch(setPaymentState({ name: "ppcp_txn_type", value: paymentOptions?.ppcp_txn_type }));
            dispatch(setPaymentState({ name: "ppcp_payment_paypal", value: paymentOptions?.ppcp_payment_paypal }));
            dispatch(
                setPaymentState({ name: "ppcp_payment_apple_pay", value: paymentOptions?.ppcp_payment_apple_pay })
            );
            dispatch(
                setPaymentState({ name: "ppcp_payment_google_pay", value: paymentOptions?.ppcp_payment_google_pay })
            );
            dispatch(setPaymentState({ name: "ppcp_mode", value: paymentOptions?.ppcp_mode }));
            dispatch(setPaymentState({ name: "ppcp_partner_fee", value: [] }));
            dispatch(
                setPaymentState({
                    name: "ppcp_contingency_3d_secure",
                    value: paymentOptions?.ppcp_contingency_3d_secure,
                })
            );
            dispatch(
                setPaymentState({
                    name: "ppcp_thirdparty_token",
                    value: paymentOptions?.ppcp_thirdparty_token,
                })
            );
            dispatch(
                setPaymentState({
                    name: "ppcp_thirdparty_token_created_at",
                    value: paymentOptions?.ppcp_thirdparty_token_created_at,
                })
            );
        }
    }, [location.search]);

    const IsDev = () => {
        return process.env.REACT_APP_NODE_ENV === undefined || process.env.REACT_APP_NODE_ENV === "development";
    };

    const OnboardHandler = async () => {
        const result: any = await dispatch(getOnBoardLink({ ppcp_mode: ppcp_mode, is_dev: IsDev() }));
        if ((ppcp_enabled == undefined || ppcp_enabled == false) && result) {
            try {
                window.open(result, "_blank");
            } catch (error) {
                console.error("Error starting PayPal onboarding:", error);
            }
        }
    };

    const DisconnectHandler = () => {
        dispatch(setPaymentState({ name: "ppcp_enabled", value: false }));
        dispatch(setPaymentState({ name: "ppcp_merchant_id", value: "" }));
        dispatch(setPaymentState({ name: "ppcp_txn_type", value: "" }));
        dispatch(setPaymentState({ name: "ppcp_merch_name", value: "" }));
        dispatch(setPaymentState({ name: "ppcp_merch_phone", value: "" }));
        dispatch(setPaymentState({ name: "ppcp_payment_paypal", value: false }));
        dispatch(setPaymentState({ name: "ppcp_payment_apple_pay", value: false }));
        dispatch(setPaymentState({ name: "ppcp_payment_google_pay", value: false }));
        dispatch(setPaymentState({ name: "ppcp_mode", value: "sandbox" }));
        dispatch(setPaymentState({ name: "ppcp_partner_fee_value", value: "" }));
        dispatch(setPaymentState({ name: "ppcp_partner_fee_type", value: "" }));
        dispatch(setPaymentState({ name: "ppcp_contingency_3d_secure", value: false }));
        dispatch(setPaymentState({ name: "ppcp_thirdparty_token", value: false }));
        dispatch(setPaymentState({ name: "ppcp_thirdparty_token_created_at", value: false }));
    };

    const changeUTCDateTimeToLocalDateTime = (UTCDateTimeString: string): string => {
        const UTCDateTime = new Date(`${UTCDateTimeString}Z`);
        return UTCDateTime.toLocaleString();
    };

    const handleGenerate3rdPartyAccessToken = () => {
        dispatch(generatePPCP3rdPartyToken());
    };

    const handleRevoke3rdPartyAccessToken = () => {
        dispatch(revokePPCP3rdPartyToken());
    };

    const switchChange = (name: string, value: any) => {
        return dispatch(setPaymentState({ name, value: isBool(!value) }));
    };

    const getCheck = (title: string, value: any) => (
        <div className="halfish-size d-flex flex-column justify-content-start align-items-start">
            <div className="payment-subtitle">
                <FormattedMessage id={title} />
            </div>
            <Input type={"text"} className="custom-input-textfield halfish-size-input" value={value} readOnly />
        </div>
    );
    const OnboardButton = () => (
        <div>
            <div className="payment-title-container">
                <FormattedMessage id={"ppcp_account_setting"} />

                {renderHalfTxtSelec("ppcp_mode", "ppcp_mode", ppcp_mode, modeOptions, setState, true)}

                <div className="payment-subtitle mr-3 mb-0">
                    <Button
                        type="primary"
                        size="large"
                        onClick={() => {
                            OnboardHandler();
                        }}
                        className="save-button-handle-onboard"
                    >
                        <FormattedMessage id="save_changes_onboard" />
                    </Button>
                </div>
            </div>
            <div className="payment-desc">
                <FormattedMessage id={"ppcp_account_setting_tip"} />
            </div>
        </div>
    );

    const DisconnectButton = () => (
        <div>
            <div className="payment-title-container">
                <FormattedMessage id={"ppcp_account_setting"} />
                {getCheck("ppcp_mode", ppcp_mode)}

                <div className="payment-subtitle mr-3 mb-0">
                    <Button
                        type="primary"
                        size="large"
                        onClick={() => {
                            DisconnectHandler();
                        }}
                        className="save-button-handle-onboard"
                    >
                        <FormattedMessage id="disconnect_paypal_button" />
                    </Button>
                </div>
            </div>
            <div className="payment-desc">
                <FormattedMessage id={"ppcp_account_setting_tip"} />
            </div>
        </div>
    );

    const SellerProtectionButton = (
        <div>
            <strong>
                <FormattedMessage id="important_notice" />
            </strong>{" "}
            <FormattedMessage id="ensure_you" />
            <a
                style={{ borderRadius: "3px", fontWeight: "bold" }}
                onClick={() => {
                    window.open(
                        "https://www.paypal.com/us/webapps/mpp/security/seller-protection?_ga=2.243725317.1870090404.1724285673-890960049.1724285673",
                        "_blank"
                    );
                }}
            >
                <FormattedMessage id="Seller_Protection_Policy" />
            </a>
            <FormattedMessage id="important_notice_tips" />
        </div>
    );

    const renderGenerateThridPartyTokenButton = () => {
        return (
            <div className="halfish-size d-flex flex-column justify-content-start align-items-start">
                <div className="payment-subtitle">
                    <FormattedMessage id={"ppcp_thirdparty_token"} />
                </div>

                <Input.Group className="multi-input-group" compact>
                    <Input
                        className="multi-text-input"
                        name={"ppcp_thirdparty_token"}
                        value={ppcp_thirdparty_token}
                        disabled={!isSuperManagerUser()}
                        readOnly
                        suffix={
                            ppcp_thirdparty_token && isSuperManagerUser() ? (
                                <Button
                                    type="link"
                                    danger
                                    onClick={() => {
                                        handleRevoke3rdPartyAccessToken();
                                    }}
                                >
                                    <FormattedMessage id={"revoke"} />
                                </Button>
                            ) : null
                        }
                    />
                    <Button
                        className="multi-text-button"
                        type="primary"
                        disabled={!isSuperManagerUser()}
                        onClick={() => handleGenerate3rdPartyAccessToken()}
                        size="small"
                    >
                        <FormattedMessage id={"generate"} />
                    </Button>
                </Input.Group>
                {ppcp_thirdparty_token_created_at && (
                    <div className="ppcp-thirdparty-token-created-container">
                        <FormattedMessage id={"generated_at"} />
                        {` ${changeUTCDateTimeToLocalDateTime(ppcp_thirdparty_token_created_at)}`}
                    </div>
                )}
            </div>
        );
    };

    const renderMenuModal = () => (
        <PPCPModal visible={isPartnerClicked} editingRule={editingRule} onClose={() => setIsPartnerClicked(false)} />
    );

    const renderSelectedOptions = (rule: any) => {
        const selectedOptions = [];
        if (rule.extraFees.tips) {
            selectedOptions.push(intl.formatMessage({ id: "partner_extra_fee_tips" }));
        }
        if (rule.extraFees.surcharge) {
            selectedOptions.push(intl.formatMessage({ id: "partner_extra_fee_surcharge" }));
        }
        if (rule.extraFees.deliveryFee) {
            selectedOptions.push(intl.formatMessage({ id: "partner_extra_fee_delivery" }));
        }

        return selectedOptions.length > 0 ? `${selectedOptions.join(", ")}.` : "N/A";
    };
    const renderPartnerFeeSection = (rules: any[]) => {
        const allOrderTypes = ["Delivery", "InStore", "Pickup"];
        const addedOrderTypes = rules.map((rule) => rule.orderType);
        const availableOrderTypes = allOrderTypes.filter((type) => !addedOrderTypes.includes(type));

        return (
            <Descriptions title={intl.formatMessage({ id: "partner_fee_section" })} bordered>
                {rules.map((rule, index) => (
                    <Descriptions.Item
                        label={
                            <strong>{intl.formatMessage({ id: `ppcp_${rule.orderType.toLowerCase()}_fee` })}</strong>
                        }
                        span={3}
                        key={index}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <div>
                                    <strong>{intl.formatMessage({ id: "fee_type" })}:</strong>{" "}
                                    {intl.formatMessage({ id: "ppcp" + rule.feeType })}
                                </div>
                                <div>
                                    <strong>{intl.formatMessage({ id: "fee_value" })}:</strong>
                                    {rule.feeType === "fixed"
                                        ? ` ${currencySymbols[storeCurrency]}${rule.feeValue}`
                                        : ` ${rule.feeValue}%`}
                                </div>
                                <div>
                                    <strong>{intl.formatMessage({ id: "partner_fee_based_on" })}:</strong>{" "}
                                    {<FormattedMessage id={rule.feeBasedOn} />}
                                </div>
                                <div>
                                    <strong>{intl.formatMessage({ id: "additional_fees_includes" })}:</strong>{" "}
                                    {renderSelectedOptions(rule)}
                                </div>
                            </div>
                            <div>
                                {isSuperManagerUser() ? (
                                    <>
                                        <Button
                                            type="primary"
                                            onClick={() => handleEditPartnerFee(index)}
                                            className="mr-2"
                                        >
                                            {intl.formatMessage({ id: "edit" })}
                                        </Button>
                                        <Button type="primary" danger onClick={() => handleDeletePartnerFee(index)}>
                                            {intl.formatMessage({ id: "delete" })}
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <Button type="primary" disabled className="mr-2">
                                            {intl.formatMessage({ id: "edit" })}
                                        </Button>
                                        <Button type="primary" danger disabled>
                                            {intl.formatMessage({ id: "delete" })}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </Descriptions.Item>
                ))}
                {isSuperManagerUser()
                    ? availableOrderTypes.length > 0 && (
                          <Descriptions.Item span={3}>
                              <Button type="default" onClick={() => handleAddPartnerFee()}>
                                  {intl.formatMessage({ id: "add_partner_fee_rule" })}
                              </Button>
                          </Descriptions.Item>
                      )
                    : availableOrderTypes.length > 0 && (
                          <Descriptions.Item span={3}>
                              <Button type="default" disabled className="mr-2">
                                  {intl.formatMessage({ id: "add_partner_fee_rule" })}
                              </Button>
                          </Descriptions.Item>
                      )}
            </Descriptions>
        );
    };
    const handleAddPartnerFee = () => {
        setEditingRule(null);
        setIsPartnerClicked(true);
    };
    const handleEditPartnerFee = (index: any) => {
        const selectedRule = ppcp_partner_fee[index];
        setEditingRule(selectedRule);
        setIsPartnerClicked(true);
    };
    const handleDeletePartnerFee = (index: any) => {
        Modal.confirm({
            title: intl.formatMessage({ id: "confirm_delete_partner_fee" }),
            content: intl.formatMessage({ id: "confirm_delete_partner_fee_description" }),
            okText: intl.formatMessage({ id: "delete" }),
            okType: "danger",
            cancelText: intl.formatMessage({ id: "cancel" }),
            onOk: () => {
                const updatedPartnerFees = [...ppcp_partner_fee];
                updatedPartnerFees.splice(index, 1);
                dispatch(setPaymentState({ name: "ppcp_partner_fee", value: updatedPartnerFees }));
            },
        });
    };

    return (
        <div className="white-single-block d-flex flex-column justify-content-start align-items-start">
            <Prompt
                when={h.checkRequiredFilled(paymentOptions, "ppcp_setting")}
                message={intl.formatMessage({ id: "payment_option_disabled_unless_required" })}
            />
            {ppcp_enabled == undefined || ppcp_enabled == false ? OnboardButton() : DisconnectButton()}

            {ppcp_enabled ? (
                <div className="payment-input-container">
                    {renderHalfTxtInput("ppcp_merch_name", "ppcp_merch_name", ppcp_merch_name, setState, false, true)}
                    {renderHalfTxtInput(
                        "ppcp_merch_phone",
                        "ppcp_merch_phone",
                        ppcp_merch_phone,
                        setState,
                        false,
                        true
                    )}
                    {getCheck("ppcp_merchant_id", merchantIdInPayPal == "" ? ppcp_merchant_id : merchantIdInPayPal)}
                    {renderHalfTxtSelec("ppcp_txn_type", "ppcp_txn_type", ppcp_txn_type, payAction, setState, true)}
                    {renderGenerateThridPartyTokenButton()}
                    <div className="ppcp-partner-fee-row">
                        {renderPartnerFeeSection(ppcp_partner_fee == undefined ? [] : ppcp_partner_fee)}
                    </div>

                    <div className="ppcp-present-switch-row">
                        {renderHalfSwitch(
                            "ppcp_contingency_3d_secure",
                            "ppcp_contingency_3d_secure",
                            isBool(ppcp_contingency_3d_secure),
                            switchChange
                        )}
                        {renderHalfSwitch(
                            "ppcp_payment_google_pay",
                            "ppcp_payment_google_pay",
                            isBool(ppcp_payment_google_pay),
                            switchChange
                        )}
                        {renderHalfSwitch(
                            "ppcp_payment_apple_pay",
                            "ppcp_payment_apple_pay",
                            isBool(ppcp_payment_apple_pay),
                            switchChange
                        )}
                        {renderHalfSwitch(
                            "ppcp_payment_paypal",
                            "ppcp_payment_paypal",
                            isBool(ppcp_payment_paypal),
                            switchChange
                        )}
                        {renderMenuModal()}
                        {SellerProtectionButton}
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default App;
