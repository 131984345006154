export default {
    "": "",
    " ": " ",
    yes: "是",
    no: "否",
    confirm: "确认",
    delete: "删除",
    delete_n_close: "删除并关闭",
    ok: "确定",
    saved: "已保存",
    close: "关闭",
    done: "完成",
    language: "语言",
    filter: "筛选",
    product: "产品",
    store: "商家",
    loading: "正在加载...",
    manage_product: "管理产品",
    manage_order: "历史订单",
    sales_report: "销售报告",
    setting: "设置",
    back: "返回",
    type_here: "在此输入...",
    lan: "语言",
    dashboard: "销售统计",
    dasher_info: "配送员信息",
    printer_setting: "打印机设置",
    app_setting: "App设置",
    store_availability_setting: "商家可用性设置",
    notification: "通知",
    warning: "警告",
    submit: "提交",
    cancel: "取消",
    comment: "备注",
    special_request: "特别要求",
    tel: "电话",
    addr: "地址",
    addr_short: "配送地址",
    street: "街边停车",
    unit: "房间号",
    buzzer: "门铃",
    city: "城市",
    province: "省份",
    country: "国家",
    not_set: "未设置",
    name: "名称",
    name_date: "日期\\名称",
    phone_number: "电话号码",
    email: "电子邮件",
    update: "编辑",
    skip: "跳过此版本",
    customer: "客户",
    admin: "管理员",
    system: "系统",
    app_version: "版本",
    current: "当前",
    newest: "最新",
    Skipped: "跳过",
    app_info: "App信息",
    no_new_noti: "没有新的通知",
    clear_all: "全部清除",
    select_date: "选择日期",
    select_time: "选择时间",
    select_all: "选择全部",
    from: "从",
    to: "至",
    all: "所有",
    uncategorized_items: "未分类产品",
    left_margin: "左边距",
    right_margin: "右边距",
    top_margin: "顶部边距",
    bottom_margin: "底部边距",
    save: "保存",
    save_n_close: "保存并关闭",
    save_n_continue: "保存并继续",
    localized: "本地化",
    primary: "第一语言",
    en: "English",
    zh: "简体中文",
    "zh-Hant": "繁體中文",
    kr: "한국어",
    de: "Deutsch",
    fr: "法文",
    disabled: "禁用",
    margin: "边距",
    top: "顶部",
    bottom: "底部",
    left: "左侧",
    right: "右侧",
    general: "通用",
    general_settings: "常规设置",
    qty: "数量",
    unable_to_load_any_data: "无法加载数据",
    status: "状态",
    new: "新建",
    reload: "重新加载",
    selected: "已选择",
    items: "产品",
    date: "日期",
    //shipping method
    delivery: "配送",
    pickup: "自取",
    eatin: "堂食",
    dine_in: "堂食",
    in_store: "店内",
    flatrate: "统一费率",
    free_shipping: "数字资产",
    quick_pay: "快速支付",
    //payment method
    pay_later: "当面刷卡支付",
    cash: "现金",
    credit_card: "信用卡",
    giftcard: "礼品卡",
    points: "积分",
    points_and_giftcard: "积分与礼品卡",
    wechat_pay: "微信支付",
    paypal_express: "PayPal",
    alipay: "支付宝",
    union_pay: "银联借记卡",
    union_pay_express: "银联信用卡",
    braintree_apple: "Apple Pay",
    braintree_credit_card: "信用卡",
    braintree_paypal: "PayPal",
    braintree_google_pay: "Google Pay",
    braintree_google_merchant_id: "Google Merchant ID",
    visa: "Visa",
    master: "Master",
    american_express: "American Express",
    discovery: "Discovery",
    jcb: "JCB",
    maestro: "Maestro",
    etransfer: "电子转账",
    card_present: "持卡支付",
    payment_type_kiosk: "自助刷卡支付",
    //status
    pending: "待处理",
    processing: "处理中",
    payment_review: "付款审查",
    completed: "完成",
    canceled: "已取消",
    closed: "已关闭",
    pending_payment: "待付款",
    holded: "持有",
    logout: "登出",
    logout_confirm_message: "您确定要注销吗？",
    force_logout_message: "您的账户已从另外一台设备上登录或者已经超时。请重新登录。",
    mark_as_paid: "标记为已支付",
    mark_as_unpaid: "标记为未支付",
    //detail
    subtotal: "商品总价",
    item_total: "商品小计",
    discount: "折扣",
    store_discount: "商家折扣",
    coupon_discount: "优惠券折扣",
    shipping_and_handling: "配送费",
    shipping_and_handling_with_distance: "配送费 ({distance})",
    total_before_tax: "税前金额",
    tax: "税",
    order_totals: "订单总计",
    order_total: "总价",
    summary: "小结",
    gift_card_payment: "礼品卡支付",
    points_payment: "积分支付",
    "points_payment_with_points": "积分支付({points})",
    "giftcard_payment_with_giftcard": "礼品卡支付({card})",
    total_paid: "已付款",
    total_due: "应付款",
    tips: "小费",
    delivery_fee_with_distance: "配送费 ({distance})",
    dlv_fee: "配送费",
    CD: "代码",
    store_data_error_message: "很抱歉，无法读取商铺信息，请稍后再试.",
    //version control
    up_to_date: "最新",
    no_update_availabe: "没有可用的更新",
    //app closing
    quit_confirm_message: "您确定要退出GoopterBiz吗？",
    incorrect_password: "密码错误",
    server_error: "服务器错误",
    //filter
    clear_fliter: "清除篩選條件",
    search: "搜索",
    first_name: "名字",
    last_name: "姓氏",
    max_amount: "最高金额",
    min_amount: "最低金额",
    today: "今天",
    tomorrow: "明天",
    yesterday: "昨天",
    this_week: "本周",
    last_week: "上周",
    last_two_weeks: "过去2周",
    this_month: "這個月",
    last_month: "上個月",
    this_year: "今年",
    last_year: "去年",
    last_seven_days: "过去7天",
    last_fourteen_days: "过去14天",
    last_thirty_days: "过去30天",
    last_365_days: "过去365天",
    enter_period: "输入时间段",
    last: "末页",
    now: "现在",
    mins: "分钟",
    add_x_mins: " + {x}分钟",
    add_x_hrs: " + {x}小时",
    set_exact_time: "设置确切时间",
    or: "或",
    //confirm_go_back
    confirm_go_back_message: "您确定要中止所有更改并退出此页面吗？",
    confirm_remove_changes: "确认删除更改",
    //no internet
    no_internet_connection: "没有网络连接",
    enter_password: "输入密码",
    unlock: "解锁",
    test_print_title: "正在打印",
    test_print_message: "如果未打印测试页，则可能出现打印机错误。请检查打印机，然后重试。",
    unstable_network: "网络速度慢或不稳定，请检查您的网络连接",
    //error handling
    incorrect_username_or_password: "Incorrect Username or Password",
    account_locked: "你的账号已被锁定, 请联系Goopter解锁你的账号。",
    login_failed_try_later: "登入失败，请稍后再试。",
    invalid_request_body: "无效的请求",
    unknow_host: "未知主机",
    over_refund: "退款金额不应大于总支付金额减去已退金额",
    partial_request_not_allowed: "此订单不能部分退款",
    braintree_partial_refund_not_allowed: "Braintree付款不支持部分退款，请改为全额退款。",
    unexpect_error: "发生意外错误, 请稍后再试。",
    update_order_failed: "更新失败，请稍后再试。",
    refund_not_settled: "付款交易已提交结算，但是尚未完成，请3小时后尝试退款。",
    braintree_error_dialog: "无法完成此订单的付款",
    live_order: "当前订单",
    comments: "备注",
    sales_summary: "销售汇总",
    show_more: "展开",
    show_less: "缩进",
    menu: "菜单",
    insufficient_permissions: "您的帐户无权访问此页面。",
    save_changes: "保存更改",
    rate_and_review: "打分并且写评语",
    rate_first_review_next: "先打分，再写评语",
    tell_us_about_our_service: "请给我们的服务提下您的宝贵意见",
    click_to_select: "点击选择",
    validate: "验证",
    unbind: "解除绑定",
    welcome: "欢迎",
    home: "主页",
    change: "更改",
    changes: "变化",
    submitted: "已提交",
    submit_for_review: "提交申请",
    submit_application: "提交申请",
    pending_for_review: "待审核申请",
    remove_from_review: "撤销申请",
    new_store_pending_review: "在线商铺申请 - 待审核",
    submit_review_success_msg: "感谢您提交在线商铺申请。我们将审核您的申请并在2个工作日内回复您。",
    edit: "编辑",
    and: "和",
    mon: "星期一",
    tue: "星期二",
    wed: "星期三",
    thu: "星期四",
    fri: "星期五",
    sat: "星期六",
    sun: "星期天",
    mo: "星期一",
    tu: "星期二",
    we: "星期三",
    th: "星期四",
    frd: "星期五",
    sa: "星期六",
    su: "星期天",
    choose: "选择",
    add: "添加",
    action: "操作",
    enable: "显示",
    enabled: "启用",
    disable: "不显示",
    view: "查看",
    attention: "提醒",
    error: "错误",
    first: "首页",
    prev: "前一页",
    next: "下一页",
    reset: "重置",
    alert_are_you_sure: "提醒：你确定吗?",
    alert_leaving_without_save: "警告：您在此页面上有未保存的更改。您想离开此页面并放弃更改吗？",
    franchise_manager: "总店管理员",
    franchise_assistant: "总店助手",
    franchise_cashier: "总店收银",
    store_manager: "店经理",
    store_assistant: "店助理",
    store_cashier: "店收银",
    delivery_manager: "配送经理",
    order_manager: "订单经理",
    delivery_staff: "配送员",
    super_user: "超级用户",
    super_admin: "Super Admin",
    group_sale_manager: "团购销售经理",
    group_sale_product_owner: "团购产品所有人",
    alert_no_result_found: "提醒：没有搜索结果",
    alert_load_failed: "提醒：无法加载记录，请稍后再试",
    copyright: "版权",
    all_rights_reserved: "© 2015-2019 Goopter Holdings Ltd. 版权所有.",
    on: "开启",
    off: "关闭",
    coupon: "优惠券",
    groupsale: "团购",
    products: "产品",
    coupons: "优惠券",
    giftcards: "礼品卡",
    groupsales: "团购",
    product_detail: "产品详情",
    coupon_detail: "优惠券详情",
    giftcard_detail: "礼品卡详情",
    groupsale_detail: "团购详情",
    product_information: "产品信息",
    coupon_information: "优惠券信息",
    giftcard_information: "礼品卡信息",
    groupsale_informaton: "团购信息",
    product_name: "产品名称",
    reward_coupon_code: "优惠券代码",
    coupon_hint_message: "带有优惠券信息的短信模版",
    coupon_expire_dt: "优惠券过期日",
    coupon_name: "优惠券名称",
    giftcard_name: "礼品卡名称",
    groupsale_name: "团购名称",
    product_type: "产品类型",
    coupon_type: "优惠券类型",
    giftcard_type: "礼品卡类型",
    groupsale_type: "团购种类",
    ko: "韩语",
    hi: "印度语",
    ja: "日本",
    order_type: "订单类型",
    type: "订单类型",
    payment_method: "付款方式",
    paylater: "稍后刷卡支付",
    free_payment: "免支付",
    unionpay_debit: "银联借记卡",
    unionpay_credit: "银联信用卡",
    previous: "上一页",
    forget_password: "忘记密码",
    phone_code_canada: "加拿大 +1",
    phone_code_united_states: "美国 +1",
    password_empty_feedback: "密码不能为空!",
    c2c_setting: "跨国配送设置",
    store_front: "客户端网页",
    store_front_shared: "客户端网页(共享域名)",
    store_front_independent: "客户端网页(独立域名)",
    sign_out: "退出",
    due_time: "要求时间",
    quick_add: "快加产品",
    pos_mode: "POS 模式",
    total: "合计",
    all_categories: "所有类别",
    copy_to_new_product: "复制到新产品",
    view_edit: "查看/编辑",
    product_copied: "已拷贝产品",
    product_copied_content: "产品复制成功。进行必要的更改，保存后会生成新产品。",
    update_price: "更改价格",
    update_stock: "更改库存",
    update_availability: "更改有效性",
    update_tax: "更改税率",
    set_enabled: "设置为启用",
    set_disabled: "设置为禁用",
    products_deleted: "已删除{count}个产品。",
    products_updated: "已更新{count}个产品。",
    products_deleted_message: "以下产品已成功删除：{success}。",
    products_updated_message: "以下产品已成功更新：{success}。",
    increase_or_decrease_message: "提高或降低所选产品的价格。",
    enter_the_value: "请输入金额",
    select_to_round: "自动调整价格",
    round_the_price: "价格取整",
    choose_percentage_amount: "选择是否要按百分比或金额更新价格",
    by_percentage: "按百分比",
    by_amount: "按金额",
    no_rounding: "不舍入",
    round_to_dollar: "四舍五入到$1",
    round_to_one_decimal: "舍入到一位小数",
    round_to_two_decimals: "舍入到二位小数",
    set_amount: "设置金额",
    increase: "增加",
    decrease: "减少",
    confirm_delete_products: "删除产品",
    confirm_delete_products_content: "您确定要删除{count}个产品吗？",
    confirm_enable_products: "启用产品",
    confirm_enable_products_content: "您确定要启用选中的{count}个产品吗？",
    confirm_disable_products: "禁用产品",
    confirm_disable_products_content: "您确定要禁用选中的{count}个产品吗?",
    size: "大小",
    number: "数量",
    color: "颜色",
    material: "材料",
    length: "长度",
    width: "宽度",
    scent: "香味",
    flavor: "口味",
    model: "款式",
    format: "格式",
    platform: "平台",
    addon: "附加",
    edition: "版本",
    presets: "预设值",
    cooking_style: "烹饪方式",
    option: "选项",
    item_title_missing_language_content:
        "该商品没有 {changedLanguagesFullNames} 名字。 {languagesFullNames} 名字 {values}，将作为该商品的{changedLanguagesFullNames}名字。 您确定要继续进行这些更改吗?",
    item_title_language_content:
        "产品名字已更改为{changed}，但在{languagesFullNames}中仍为{values}。确定要继续这些更改吗？",
    option_title_language_content:
        "选项名字已更改为{changed}，但在{languagesFullNames}中仍为{values}。确定要不进行这些更改就继续吗？",
    select_details_to_copy: "选择要复制的详细信息",
    message_disabled_warning:
        "您的通知设置已关闭； 您因此将不会收到新的或更新的订单通知。 请确保启用通知设置以得到通知。",
    dismiss: "关闭",
    discard: "放弃",
    images: "图片",
    show_images: "显示图片",
    move_category: "移动分类目录",
    move_category_confirm: "您确定要移动此分类目录吗？",
    upsell_products: "加售产品",
    recommend_a_good_restaurant: "为您挑选了一个好店",
    recommend_a_good_merchat: "为您挑选了一个好商家",
    recommend_a_good_product: "为您挑选了一个好物",
    guest_user: "匿名用户",
    image_quality: "图片质量",
    low: "低",
    medium: "中",
    high: "高",
    order_from: "订单来自于",
    os: "操作系统",
    browser: "浏览器",
    ip_address: "IP",
    copy_what: "复制{what}",
    copied_to_clipboard_success: "{what}已復制",
    copied_to_clipboard_failed: "复制{what}失败",
    success: "成功",
    failed: "失败",
    no_record: "未找到记录",
    try_other_filters: "尝试更改过滤器或搜索词",
    view_all_orders: "查看所有订单",
    read_only_message: "您当前的权限对此页面是只读的，不能进行更改。",
    online: "可下单",
    offline: "不可下单",
    no_authorization: "您无权访问此页面",
    only_jpg_png: "只能上传 JPG 和 PNG 文件。",
    must_be_lt_2m: "文件大小不得超过 2MB",
    hide_disabled_products: "隐藏禁用的产品",
    show_hidden_products: "显示隐藏的产品",
    enabled_status: "启用状态",
    enabled_products: "启用产品",
    disabled_products: "禁用产品",
    product_visibility: "产品可见性",
    visible_products: "可见产品",
    hidden_products: "隐藏产品",
    load_more: "加载更多",

    sign_in_welcome: "欢迎使用Goopter商家中心",
    sign_in_head_hint: "请输入账户登录.",
    login: "商家签到",
    username: "用户名",
    password: "密码",
    signup: "Sign Up",
    forgot_password: "忘记密码",
    by_email: "通过电子邮件",
    by_phone: "通过短信",
    signup_instead: "Sign Up Instead",
    signin_instead: "Sign In Instead",
    self_serve_signup: "Self-serve Sign Up",
    assisted_signup: "Assisted Sign Up",
    self_serve_signup_desc:
        "Set up your online store by yourself. Input your business information and product information on your own.",
    assisted_signup_desc:
        "Provide basic information about your business and let us contact you to assist you with your online store set up.",
    store_address: "Store Address",
    floor_suite_optional: "Floor/Suite (Optional)",
    business_type: "Business Type",
    go_back: "Go Back",
    verification_email_sent: "Verification Email Sent",
    verification_email_sent_desc:
        "An email with a verification link has been sent, please follow the directions in the email to confirm your account. ",
    store_sign_up: "Store Sign Up",
    price_plan_contact_details: "Price Plan & Contact Details",
    store_type_categories: "Store Type & Categories",
    contact_name: "Contact Name",
    contact_number: "Contact Number",
    number_of_locations: "Number of Locations",
    current_revenue_optional: "Current Revenue (Optional)",
    store_categories: "Store Categories",
    does_delivery: "Does Delivery",
    assisted_signup_complete: "Assisted Signup Complete",
    assisted_signup_complete_desc: "We will contact you to help you setup your store.",
    cashondelivery: "现金",
    paypal_pro: "PayPalPro",
    braintree: "Braintree",
    ccsave: "刷卡",
    online_card: "在线支付",
    giftcard_ponts: "GiftCard|Points",
    wechatpay: "微信",
    goopterexpress: "PayPal",
    free: "无支付",
    unionpay: "银联信用",
    unionpayexpress: "银联借记",
    printer: "打印机",
    staff: "员工",
    operation: "操作",
    hourly: "小时",
    attendance: "Attendance",
    export_type: "导出类型",
    includes_order_detail: "包括订单详细",
    show_report: "查看报表",
    print_report: "打印报表",
    export_report: "导出报表",
    tippings: "小费",
    staff_report: "员工报表",
    total_cnt: "数量",
    total_amt: "总金额",
    total_tips: "小费",
    time: "时间",
    amt: "金额",
    daily_summary: "每日总计",
    period_summary: "合计",
    please_select_a_printer: "请选择打印机",
    report_type: "报表类型",
    normal_staff_report: "员工销售详细",
    staff_summary: "员工销售汇总",
    customer_summary: "自助下单汇总",
    sales: "销售",
    update_categories: "更改分类目录",
    update_category_ids: "更改分类目录",
    store_switch: "Store Switch",
    store_switch_successful: "切换商家成功",
    switched_to_store: "切换到商家 {name}.",
    switch_approved: "切换到已批准的",
    switch_wip: "切换到还在批复中的",
    wip_store: "还在批复中的商家",
    approved_store: "已批复的商家",
    business_name: "商家名称",
    business_bio: "商家介绍",
    business_address: "商家地址",
    address: "地址",
    address_line_1: "地址第一行",
    address_line_2: "地址第二行",
    postal_code: "邮编",
    contact_information: "联系信息",
    phone: "电话",
    twitter: "Twitter",
    instagram: "Instagram",
    facebook: "Facebook",
    website: "网页",
    store_type_and_categories: "商家类型和分类",
    restaurant: "餐厅",
    shopping: "商店",
    service: "服务",
    time_zone: "时区",
    latitude: "纬度",
    longitude: "经度",
    store_information: "商店信息",
    store_information_description: "贵公司的名称和联系信息将呈现在您的在线商铺上，并将出现在客户收据上。",
    hour: "营业时间",
    store_images: "商家图片",
    price_plan_and_currency: "价格计划，税号，货币设置",
    owner: "联系人",
    store_name: "商家名称",
    seo_title: "搜索引擎优化",
    seo: "搜索引擎优化",
    seo_description:
        "指定搜索引擎用来为产品建立索引的链接和元数据字段。最佳做法是在元数据和元描述中都包含高价值关键字。",
    currency_vat_number: "货币和税号",
    currency_vat_number_description: "增值税号用于在发票上显示增值税号码。例如，GST号吗",
    price_plan: "价格计划",
    price_plan_description: "价格计划决定您的在线商店的功能及费用。",
    meta_title: "元信息标题",
    meta_keywords: "元信息关键词",
    meta_description: "元信息简介",
    url_key: "元信息键",
    search_engine_optimization: "优化搜索引擎",
    currency: "货币",
    vat: "VAT号码",
    details: "详细",
    store_pa: "商家公告",
    store_pa_en_placeholder: "在此输入",
    public_announcement_description: "输入您希望客户在主页上看到的简短公告。",
    branding: "品牌信息",
    branding_description: " 您的品牌信息适用于收据，发票，预订和市场营销。",
    logo: "JPG 徽标",
    banner_desktop: "标题图片(桌面版本)",
    banner_desktop_description: "此图片出现在电脑浏览器主页的标题部分。 建议尺寸为1234 x 345像素。",
    banner_mobile: "标题图片(移动版本)",
    banner_mobile_description: "此图片出现在移动设备浏览器主页的标题部分。 建议尺寸为678 x 167像素。",
    store_images_description: "添加图像以展示您的商店。 您可以通过单击查看图片。",
    display_options: "显示选项",
    product_display_format: "产品显示格式",
    product_detail_display_format: "产品详情显示格式",
    product_detail_display_format0: "打开弹出",
    product_detail_display_format1: "在新标签中打开全部详情",
    product_detail_display_format2: "在同一窗口中打开所有详情",
    product_detail_display_format3: "点击图片打开弹出, 点击标题在新标签里打开详情",
    product_detail_display_format4: "点击图片打开弹出, 点击标题在同一窗口中打开所有详情",
    product_detail_display_format5: "点击标题打开弹出, 点击图片在新标签里打开详情",
    product_detail_desc: "产品详细信息显示格式是用来定义当用户单击产品列表页面上的产品时如何打开产品详细信息页面。",
    store_qty_display: "库存数量显示",
    store_qty_display_amount: "库存数量显示阀值",
    store_qty_display_tip: "决定何时显示库存数量",
    store_qty_display_amount_tip: "库存小于指定值时显示库存:",
    sold_qty_display: "产品销量显示",
    sold_qty_display_amount: "产品销量显示阀值",
    sold_qty_display_tip: "确定何时显示产品销量。",
    sold_qty_display_amount_tip: "当销量大于或等于该值时显示产品销量。",
    dont_show_stock_quantity: "不显示库存",
    always_show_stock_quantity: "一直显示库存",
    dont_show_sold_quantity: "不显示销量",
    always_show_sold_quantity: "一直显示销量",
    show_stock_quantity_when_under_certain_amount: "低于指定值时显示库存",
    show_sold_quantity_when_over_certain_amount: "当销量超过指定值才显示",
    regular: "标准格式",
    super_market_style: "大图片格式",
    one_page_store: "单页面格式",
    regular_style_hidden_cat: "标准格式隐藏目录",
    horizontal_category_display: "横向显示目录",
    no_store_info_and_review_tab: "没有商家信息和评论页",
    with_store_info_tab_no_review_tab: "有商家信息页，无评论页",
    with_store_info_and_review: "有商家信息和评论页",
    product_display_languages: "产品显示语言",
    default_language: "默认语言",
    store_display_style: "商家页面显示样式",
    product_display_format_tip:
        "在手机浏览器中，超级市场风格默认显示网格视图的产品列表，并且能够在网格视图和列表视图之间切换；常规样式仅在列表视图中显示产品列表",
    pagination: "分页功能",
    pagination_tip:
        "分页功能通常支持150多种产品以上的商店，当分页模式打开的时候，产品将根据所选类别加载，每页限制为20个产品；当分页模式关闭时，所有产品同时加载入页面",
    show_product_place_holder_image: "显示默认图片",
    show_product_place_holder_image_tip: "如果未提供图像，则显示每个产品的默认图像",
    hot_category_enabled: '显示 "热销" 目录',
    onsale_category_enabled: '显示 "促销" 目录',
    hot_category_enabled_tip: "促销类别显示前十个最优惠的商品",
    onsale_category_enabled_tip: "热销类别显示前十个销量最大的商品",
    use_land: "使用定制首页",
    use_land_tip:
        "添加一个定制首页来吸引访客的注意力。请按照定制首页的语法或联系support@goopter.com，或致电778-379-7918寻求帮助。",
    payment_options: "支付选项",
    accept_credit_card: "接受信用卡支付",
    accept_cash: "接受现金支付",
    allow_pay_later: "接受稍后刷卡支付",
    allow_qr_checkout: "虚拟支付终端",
    setup_fee: "开户费",
    monthly_fee: "月费",
    maximum_languages: "最多支持语言",
    price_range: "价钱范围",
    quantity_range: "数量范围",
    under_10: "低于 $10",
    "10_30": "$10-30",
    "30_60": "$30-60",
    "60_99": "$60-99",
    above_100: "高于 $100",
    value_to_points_ratio: "订单金额与奖励积分比例",
    points_to_value_ratio: "奖励积分与货币单位的比率",
    points_to_value_ratio_tip:
        "奖励积分与货币单位（$, ¥, €,等）的比例，表示多少积分相当于一个货币单位。比如该字段的值设置为10，则每10个奖励积分将等于1个货币单位。(即10奖励积分=$1）",
    value_to_points_ratio_tip:
        "订单金额(产品金额)与积分的比率表示根据客户花费的金额获得多少积分。它应该是一个介于0和999之间的数字。如果该字段的值设置为10，客户的订单金额为$50, 完成订单后客户将获得500积分。",
    value_to_points_ratio_alert_integer: "价值与积分比必须是整数",
    value_to_points_ratio_alert_range: "价值与积分比需在0至999之间",
    allow_online_order: "允许网上下单",
    allow_online_order_tip: "Allow Online Order Tip",
    allow_reward_points_tip:
        "奖励积分有助于提高客户忠诚度并吸引新客户。 要启用奖励积分，您需要在Goopter进行存款，请致电支持778-379-7918为您的商店开启奖励积分功能",
    allow_order_when_store_closed: "关店后允许下单",
    allow_preorder_time: "营业前多少分钟开始接受预订",
    anytime: "任何时候",
    not_allowed: "不允许预订",
    tips_settings: "小费设置:",
    enable_tips: "启用小费",
    no_tips: "无小费",
    order_limit: "触发小费规则的订单金额",
    hint_order_limit: "如果商品累计超过指定金额，那么客户端将安装百分比显示小费选项，否则将显示金额显示小费选项",
    default_percentage_values: "默认小费百分比值",
    hint_default_percentage_values: "订单金额超过指定金额时的默认小费的百分比值(按配送，店内和自取的顺序)",
    default_amount_values: "默认小费金额值",
    hint_default_amount_values: "订单金额小于指定金额时的默认小费金额(按配送，店内和自取的顺序)",
    tips_in_percentage: "小费的百分比选项",
    hint_tips_in_percentage: "订单金额超过指定金额时的百分比选项, 默认值为 0, 10, 12, 15, 20",
    tips_in_dollar_values: "小费的金额选项",
    hint_tips_in_dollar_values: "订单金额小于指定金额时的金额选项, 默认值为 0, 2, 3, 4, 5",
    settings_overview: "设置总览",
    nav_dashboard: "控制面板",
    business_features: "商家特征",
    restaurant_extras: "餐厅相关信息",
    store_configuration: "商家设置",
    allow_order_online_description: "您可以打开或关闭在线订购开关。 关闭后，商品仍将保留在您的在线商店中，但无法下单。",
    unpaid_order_cancellation: "在线支付类型未付款的订单取消时间",
    unpaid_order_cancellation_desc:
        "在预定义的时间之后，如果支付类型为在线付款但在下订单时未付款，则系统将自动取消订单。",
    outdoor_seats: "户外座位",
    accept_qr_checkout: "接受扫码付款",
    accept_qr_tip: "扫码支付是允许您的客户扫描提供的二维码在他们自己的手机上完成支付，为您的收银员节省时间。",
    eatin_area_setting: "店内用餐设定",
    good_for_business: "商务优惠",
    estimate_preparation_time: "预计准备时间(分钟)",
    estimate_preparation_tip: "准备时间是商家在接到订单后准备订单的平均时间。您可以根据您的情况更改此时间设置。",
    delivery_setting: "送货设置",
    delivery_time_delta: "送货时间范围(分钟)",
    delivery_time_delta_tip:
        "配送时间范围设置是配送的时间增量(以分钟为单位)。例如，设置为10可以产生可用的时间段如10:00-10:10，10:10-10:20...。",
    allow_pick_up: "允许到店自取",
    allow_out_call: "允许上门服务",
    pickup_time_delta: "自取时间范围(分钟)",
    outcall_time_delta: "上门服务时间范围(分钟)",
    pickup_time_delta_tip:
        "自取时间范围设置是自取的时间增量(以分钟为单位)。例如，设置为10可以产生可用的时间段如10:00-10:10，10:10-10:20...；设置为15可以产生可用的时间段如：10:00-10:15, 10:15-10:30…等。",
    outcall_time_delta_tip:
        "上门服务时间范围设置是上门服务的时间增量(以分钟为单位)。例如，设置为10可以产生可用的时间段如10:00-10:10，10:10-10:20...；设置为15可以产生可用的时间段如：10:00-10:15, 10:15-10:30…等。",
    allow_up_sale: "允许加售",
    up_sale_tip:
        "加售是商家用来增加销售和利润的常用策略，通常选择买家可能购买的相关产品作为加售的产品，这样子买家可能会在结账之前将这些产品添加到购物车里。",
    min_delivery_amount: "最小送货量",
    min_pickup_amt: "最少自取金额",
    store_features: "商家特色",
    nagative_number_alert: "必须是正数",
    "3rd_party_local_delivery_setting": "第三方同城配送设置:",
    prepare_order_threshold_desc:
        "开始准备时间“定义了商家通常提前多少时间为预定的订单开始准备。 如果订单未及时设置为“开始准备“，系统将显示提醒对话框。",
    surcharge_label_name: "附加费名称",
    surcharge_order_type_any: "所有订单",
    surcharge_amount: "附加费金额",
    surcharge_type: "附加费类型",
    surcharge_min: "附加费最低金额",
    surcharge_max: "附加费最高金额",
    surcharge_tax_class: "附加费税率",
    surcharge_order_type: "附加费订单类型",
    language_tooltip_surcharge: "您可以在此菜单中找到已本地化的语言",
    language_tip:
        "如需查看、修改与语言相关的栏目请选择对应的语言， 以下栏目支持多语言设置：商家名称、商家描述、商家公告、商家折扣信息",
    notice_infomation: "公众通告",
    last_publish_time: "最后一次发布时间",
    meta_title_tip: "元标题是出现在浏览器窗口顶部的文本。 您可以使用基于产品名称的默认值，或根据需要进行更改",
    meta_title_tip_content_pages:
        "元标题是出现在浏览器窗口顶部的文本。您可以使用此页面名称作为的默认值，或根据需要更改它",
    meta_keywords_tip: "一些搜索引擎使用元关键字比别人更多。 输入一些高价值的关键词，以帮助产品获得更多的知名度",
    meta_keywords_tip_content_pages:
        "某些搜索引擎比其他搜索引擎更多地使用元关键字。输入几个高价值的关键词，帮助页面获得更多的搜索",
    meta_description_tip: "元描述是出现在搜索结果列表中的文本。 为获得最佳效果，请输入长度介于150-160个字符之间的描述",
    republish_tip: "您的商店只有在发布之后才能被消费者查看, 取消发布可以隐藏公共查看",
    store_menu_tip: "如果您的商店中有多个菜单，您可以选择相应的菜单，并允许您的客户在不同的菜单之间切换下单",
    store_type_categoreis_tip: "您最多可以选择三类商店描述",
    vat_number_tip: "增值税号用于在发票上显示增值税号码。例如，GST号吗",
    search_engine_optimization_tip:
        "搜索引擎优化部分指定搜索引擎用于索引产品的URL密钥和元数据字段。 目前的SEO最佳做法是在元标题和元描述中加入高价值关键字",
    c2c_shipping_product_tip:
        "在产品上设置运费将覆盖商家级别的运费设置，如果产品的运费和商家上设置的运费一致的话就无需在产品上设置运费",
    alert_time_overlapping: "输入时间重叠",
    alert_update_failed: "更新失败！请检查您的输入！",
    alert_success_publish: "发布成功！",
    alert_success_publish_no_delivery:
        "您的商铺已成功发布，因为配送时间还没有定义，系统关闭了您的送货服务。如果需要开启配送服务，请在设置中打开配送服务，然后添加配送时间。",
    alert_failed_publish: "发布失败！",
    alert_success_unpublish: "取消发布成功！",
    alert_failed_unpublish: "取消发布失败！",
    alert_select_store_type: "请选择至少一个商家类型!",
    alert_success_submit_for_review: "提交审查!",
    alert_success_unsubmit: "未提交!",
    alert_failed_submit_for_review_failed: "提交审查失败!",
    alert_failed_unsubmit: "无法提交",
    apply_new_branch: "申请新商家",
    new_store: "新商家",
    store_pending: "等待审核",
    store_rejected: "审核不通过",
    store_approved: "通过审核",
    store_prepare_for_submission: "准备提交",
    store_pending_approval: "等待审核",
    store_info: "商家信息",
    publishing: "发布中",
    publish: "公开",
    unpublish: "不发布",
    republish: "重新发布",
    unsubmit: "取消提交",
    store_status: "商家状态： ",
    general_information: "基本信息",
    store_type: "商家类型",
    store_menu: "商家菜单",
    store_image: "商家图片",
    open_hour: "营业时间",
    delivery_hour: "配送时间",
    service_information: "服务设置",
    order_notification: "订单提示",
    payment_setting: "支付设置",
    discount_public_notice: "公告",
    account_information: "账号信息",
    store_contact_number: "商家联系号码",
    store_website_url: "商家网站URL",
    store_website_url_tip: "网站URL是与您的域名绑定的，如果您需要更改，请联系support@goopter.com或致电778-379-7918。",
    store_h5_url: "触屏版网址",
    store_mobile_website_url_tip: "触屏版网址",
    contact_person: "店主",
    currency_and_vat_number: "货币和VAT号码",
    select_a_currency: "请选择交易货币",
    vat_number: "VAT Number",
    store_type_subtype: "商家类型和分类",
    no_image: "没有图片",
    image_hint_do_not: "这个产品没有任何图片",
    open_24_hours: "一周24小时营业",
    open: "开门",
    opens_at: "开门时间",
    closes_at: "关闭时间",
    add_hours: "添加时间",
    delivery_24_hours: "一周24小时配送",
    street_address: "街道地址",
    buzz: "Buzz #",
    timezone: "时区",
    post_code: "邮编",
    basic_setting: "基础设置",
    is_hold_ordering: "停止网上下单",
    stop_order_minutes_before_close: "商家关闭的前x分钟停止下单",
    order_expiration_limit: "自动取消未支付的订单的等候时间(仅限于支付宝，微信，银联支付)",
    above_60: "高于 $60",
    allow_reward_points: "启用奖励积分",
    ranking: "排行",
    require_billing_address: "需要完整的账单地址",
    require_billing_address_tip:
        "建议商家要求客户提供帐单地址以开启严格的账单地址验证，当不需要完整的帐单邮寄地址时，客户只需要提供帐单地址的邮政编码，请注意如果不需要完整的帐单地址发生欺诈的风险也会上升。商家需要为欺诈的交易自己承担责任。",
    accept_wechat_pay: "接受微信支付",
    online_status: "在线状态",
    allow_apple_pay: "接受Apple Pay",
    allow_take_out: "允许外带",
    allow_in_store_purchase: "允许店内购买",
    allow_in_store_service: "允许店内服务",
    allow_eat_in: "允许店内用餐",
    require_table_number: "要求店内用餐时提供餐桌号码",
    allow_delivery: "允许配送",
    delivery_distance: "固定收费交付距离",
    delivery_fee: "基本配送费",
    delivery_fee_tip: "基本配送费是在固定收费配送距离内收取的配送费用",
    extra_dollar_amount: "超出部分每公里金额",
    estimate_delivery_time: "预计送达时间 (分钟)",
    max_delivery_distance: "最远配送距离",
    allow_3rd_party: "使用第三方配送",
    minutes: "分钟",
    free_services: "免费服务:",
    tv_available: "提供电视",
    wifi: "提供无线网",
    alcohol: "酒水",
    no_such_info: "没有这个信息",
    beer_wine_only: "只提供啤酒和红酒",
    full_bar: "全部提供",
    noise_level: "噪音等级",
    quiet: "安静",
    average: "一般",
    loud: "热闹",
    very_loud: "吵闹",
    environment: "环境",
    intimate: "私密",
    hipster: "时尚",
    classy: "优雅",
    upscale: "高档",
    parking: "停车",
    free_lot: "免费停车位",
    paid: "已支付",
    allow_waitlist: "允许等候",
    allow_office_delivery: "允许指定地址送货",
    waiter_service: "有服务员",
    take_reservation: "允许订位",
    bike_parking: "提供自行车停车位",
    good_for_group: "适合团体",
    good_for_kids: "适合孩童",
    casual_attire: "着装",
    casual: "随意",
    dressy: "正装",
    notify_by_text_message: "短信提示",
    receive_order_by_sms: "启用短信接收订单提示",
    receive_sms_to: "接收短信的号码：",
    notify_by_email: "电子邮件提示",
    notify_by_email_tip: "要打开电子邮件通知，请先在资料中提供有效的电子邮件",
    receive_order_by_email: "启用电子邮件接收订单提示",
    receive_email_to: "发邮件到：",
    notify_by_push: "启用推送提示",
    notify_by_push_tip: "为了启用推送通知功能，您需要下载并安装Goper biz应用程序，然后使用您的管理员帐户登录",
    receive_order_by_phone: "通过苹果或者安卓设备接收订单提示",
    order_printing: "打印订单",
    smart_printer_setting: "智能打印设置",
    auto_print_printer: "自动用一体化智能打印机打印",
    auto_print_browser: "自动用浏览器打印订单",
    printer_language: "打印机语言",
    primary_language: "第一语言",
    secondary_language: "第二语言",
    select_a_language: "选择一种语言",
    printer_selection: "打印机选择",
    select_a_model: "选择一种型号",
    gt6000sw: "GT6000SW",
    fcs10w: "FCS10W",
    printer_model: "打印机型号",
    printer_access: "进入打印机",
    no_flex_tip: "需要订购Flex Plan才能访问此功能",
    "customer_order_notification_by_text_message": "客户订单短信提醒",
    "order_notification_confirmation": "订单通知验证",
    "enable_notification_validation": "启用通知接收人验证",
    "enable_notification_validation_tip":
        "启用“通知接收人验证“后，系统将验证在线用户收到了订单通知。如果系统在三次尝试后仍无法通过验证，则会向管理员用户发送电子邮件和短信提醒。",
    flex: "交易佣金计划",
    use_3rd_party_tip:
        "第三方运输是使用第三方的运送服务，例如FedEx，UPS等。当您打开第三方运输服务时，您需要在设置 - > 邮寄服务中设置配送",
    logo_resolution_tip:
        "徽标图像将显示在您的网上商店顶部。您只有上传一个徽标，保存一个新的徽标将取代现有的徽标。我们建议采用正方形，分辨率为256px x 256px的jpg 或者png图片。",
    header_resolution_tip:
        "此商标标题图片将用于显示您的网站顶部(电脑浏览器版本)，如果您不提供的话，将会有一个默认的图像显示在您的网站上。建议的尺寸是1920px x 190px像素的jpg 或者png图片。上传并保存新的图片将替换现有的标题图片。",
    header_resolution_mobile_tip:
        "此商标标题图片将用于显示在您的移动版本在线商店的顶部(触摸屏智能手机或平板电脑)。建议的尺寸是1024px x 256px像素的jpg或者png图片。上传并保存新的图片将替换现有的标题图片。",
    store_images_info: "添加照片来展示您的商店。您可以通过单击图像并拖动来改变排序。",
    is_hold_ordering_tip: "打开这个开关可以暂停在线订单",
    add_replace_image: "添加/替换图片",
    add_image: "添加图片",
    uploading: "上传中...",
    store_logo: "JPG 格式商家徽标",
    store_logo_hint: "jpg格式的徽标是显示在客户网站上用的。请确保它是正方形的，最好有圆边框。推荐尺寸为500px*500px。",
    qr_logo: "PNG 徽标",
    qr_logo_hint: "PNG格式的徽标是用在商家二维码上的，应与JPG格式的徽标一致。请确保在圆圈的边界外是透明的背景。",
    store_header: "商家标题图片(桌面版本)",
    store_header_mobile: "商家标题图片(移动版本)",
    store_photos: "商家图片",
    alert_close_after: "关门时间必须早与下一个开门时间",
    alert_close_before_4am: "跨天营业的结束时间必须早于4am",
    alert_open_after: "营业时间必须早于关门时间",
    alert_empty_time: "无效时间",
    alert_min_working_time: "关门时间比开门时间至少要晚30分钟",
    price_plan_id_26: "个性计划",
    price_plan_id_22: "全面计划",
    price_plan_id_21: "基本计划",
    price_plan_id_20: "免费试用",
    price_plan_id_0: "基本计划",
    landing_page: "登录页",
    landing_page_desktop: "桌面登录页",
    landing_page_mobile: "手机登录页",
    goopter_delivery: "Goopter 配送",
    store_id: "商家ID",
    auto_create_task: "新订单自动创建配送任务",
    _placeholder: "在此输入...",
    store_information_saved: "商店信息已成功更新。",
    general_setting: "综合",
    taxes_setting: "税",
    item_setting: "产品",
    business_hours: "营业时间",
    shipping_setting: "邮费设置",
    permission_setting: "权限",
    pickup_delivery_setting: "自取和配送设置",
    menu_setting: "菜单",
    self_serve_ordering_setting: "店内扫码下单",
    availability_setting: "可用代码和时间对照表",
    managed_stores_setting: "管理的商家",
    notifications_setting: "通知",
    public_notice_setting: "公告",
    other_setting: "其它",
    general_setting_description: "自定义和编辑信息，例如商店地址，联系方式等。  添加一个有关您的商店的简介。",
    taxes_setting_description: "",
    item_setting_description: "",
    business_hours_description: "让客户知道你们什么时候营业。 让客户知道你们何时提供配送。",
    shipping_setting_description: "启用第三方配送服务，例如FedEX，UPS，设置免费配送金额等。",
    permission_setting_description: "管理不同类型的用户的权限",
    pickup_delivery_setting_description: "自定义适合你们业务和客户需求的自取和配送设置。",
    menu_setting_description: "创建多个菜单，更新菜单的分类；选择每个菜单的有效订单类型。",
    self_serve_ordering_setting_description: "提供自助服务，减少和客户面对面的接触。轻松创建并管理每个台面的二维码。",
    availability_setting_description: "让客户知道何时提供某种类型的菜单。指定不同菜单的可用日期和时间。",
    managed_stores_setting_description: "连锁店总经理可以管理来自多个商铺的订单。 选择您要管理的商铺。",
    notifications_setting_description: "如果您想接收订单状态和其它更改的通知，请启用推送通知。",
    public_notice_setting_description: "输入您希望客户在主页上看到的简短公告。",
    select_order_types: "请选择订单类型",
    other_setting_description: "",
    payment_options_description: "启用适用于您商店的支付方式，给您的客户提供更多付款选择可以增加销售。",
    display_options_description: "自定义商铺的显示设置，包括默认语言，显示样式，产品显示格式，如何分页等。",
    tips_settings_description: "按照百分比和金额定义的小费金额，适用于配送，自取和店内消费。",
    availability_settings: "可用性设置",
    availability_description:
        "可用性代码提供了唯一的代码，可以用来代表一周中每一天的可用时间。如果您的生意有产品只能在指定日期或者时间才能下单的话，则可以定义可用性代码，并将其分配给相应的产品或分类目录。",
    actions: "操作",
    code: "代码",
    hours: "时间",
    business_hours_description_short: "让客户知道你们什么时候营业。",
    delivery_hours: "配送时间",
    delivery_hours_description: "选择配送时间",
    store_description: "商店描述",
    includes_tips: "包含小费信息",
    "show_tax_details": "显示税金明细",
    "tax_details": "[税金明细]",
    "tax_total": "税金总计",
    entire_sales_summary: "全店销售汇总",
    entire_store_sales_detail: "全店销售明细",
    staff_sales_detail: "员工销售详细",
    self_order_sales_summary: "自助下单总结",
    allow_takeout: "允许自取",
    specify_pickup: "允许多个取货点",
    specify_pickup_desc: "如果您有多个取货点的话，请打开这个开关并且制定取货地址和时间。",
    location_name: "区域名称",
    days: "日期",
    delete_availability_code: "删除有效时间代码",
    availability: "时间表",
    add_pickup_availability: "添加自取时间",
    pickup_time_range: "自取时间范围",
    pickup_time_range_desc:
        "自取时间范围设置是自取的时间增量(分钟)。例如，设置为10可以生成如下时间段，10:00-10:10, 10:10-10:20。",
    min_pickup_amount: "最少自取金额",
    estimated_preparation_time: "预计准备时间",
    estimated_preparation_time_desc: "准备时间是商家准备订单的平均时间。您可以根据自己的情况更改此时间设置",
    specify_delivery: "基于地区的固定配送费",
    specify_delivery_desc:
        "如果您的配送时间和费用基于区域/城市，请打开此开关并定义配送区域，费用以及每个区域的配送时间。",
    zone_name: "配送区域名称",
    add_delivery_availability: "添加配送时间表",
    flat_delivery_fee_distance: "平价配送距离",
    base_delivery_fee: "基本配送费",
    extra_dollar_per_km: "超过距离后的每公里配送费",
    delivery_time_range: "配送时间范围",
    delivery_time_range_desc:
        "配送时间范围设置是配送的时间增量(分钟)。例如，设置为10可以生成如下时间段，10:00-10:10, 10:10-10:20。",
    fee_delivery_amount: "最少配送金额",
    free_delivery_amount: "免费配送金额",
    edit_zone: "编辑区域",
    add_time_slot: "添加时间段",
    are_you_sure_delete_slot: "确定要删除此时间段吗？",
    days_placeholder: "选择日子",
    all_day: "全天",
    add_new_boundary: "添加区域",
    remove_selected_boundary: "删除选中的区域 ",
    edit_boundary_data: "编辑区域数据",
    backup: "备份",
    restore: "恢复",
    restore_options: "恢复选项",
    restore_options_message_delivery: "请选择一个选项来恢复您的配送区域和时间设置。",
    restore_options_message_pickup: "请选择一个选项来恢复您的自取区域和时间设置。",
    overwrite_existing_records: "覆盖现有记录",
    append_to_existing_records: "追加到现有记录后",
    choose_file: "选择文件",
    add_availability_code: "添加时间代码",
    create_new_code: "添加新代码",
    enter_new_code: "输入新代码",
    enter_new_code_name: "输入新代码名称",
    empty_code_input: "请输入时间代码",
    empty_code_name_input: "请输入可用时间名称",
    code_already_exists: "重复的时间代码",
    use_a_new_code: "时间代码已经存在。请输入其它代码。",
    unsaved_changes: "您有未保存的修改",
    sure_to_exit: "确定要退出吗?",
    end_before_start_time: "结束时间必须晚与起送时间",
    please_change_time: "请更改您的时间设置",
    edit_availability: "更改时间代码设置",
    missing_fields: "缺失的字段",
    missing_fields_message: "以下字段缺失: {missing}",
    missing_time_ranges: "时间输入不能为空。",
    please_input_address: "请输入地址..",
    self_serve_ordering: "自助下单(扫码下单)",
    display_self_order: "显示自助下单操作步骤",
    generate_qr: "生成二维码",
    accept_self_serve: "接受店内自助下单",
    require_table_num: "自助订单时需要桌号",
    order_confirmation: "订单确认",
    require_due_time_for_in_store: "店内订单需要就绪时间",
    require_due_time_for_in_store_description: "当此开关打开时，商家在确认每个店内订单时需要设置预计就绪时间。",
    dine_in_confirmation: "店内订单需要确认",
    dine_in_confirmation_description:
        "当此开关关闭时，商家将不需要在接受或拒绝店内订单时进行确认。即只需一键即可接受或拒绝订单。",
    allow_self_serve: "允许店内自助下单",
    allow_self_serve_description:
        "自助下单使您的客户在店里时可以使用自己的手机快速下单。您可以为每个企台生成一个二维码，让您的客户可以使用扫码下单功能。",
    print_your_qr: "企台号码设置",
    print_your_qr_description:
        "设置企台数量，和在卡片上显示的标签，例如桌子，展位或柜台座位。系统可以为每个企台创建一个唯一的二维码。只需打印代码并将其放在每个企台上即可。",
    exclude_station_desc: "不包括企台号码",
    num_stations: "企台数量",
    station_label: "企台标签",
    discount_label: "特价标签",
    custom_text_1: "自定义标签1",
    custom_text_2: "自定义标签2",
    start_number: "起始号码",
    station_label_placeholder: "比如 桌号",
    custom_text_placeholder: "比如 Free Wi-Fi: Goopter-Wi-Fi",
    custom_text_placeholder_2: "比如 Wi-Fi Password: 12345678",
    card_example: "卡片示例",
    card_example_title: "商家名称",
    card_example_quote: "在您自己的设备上下单，我们会将订单交到您的手中",
    card_example_instructions: "请将摄像头对准二维码扫描以打开菜单",
    card_example_table: "桌号 42",
    order_from_phone: "通过您的手机下单",
    no_app_required: "无需下载App",
    open_phone_camera: "打开手机摄像头",
    scan_qr_code: "扫码看菜单",
    order_and_we_deliver: "您只管下单，我们会将订单交到您的手中",
    order_again_qr: "要再次下单，请扫描二维码",
    sync_cart_with_server: "购物车同步到服务器",
    sync_cart_with_server_description:
        "開啟“與服務器同步購物車“將允許客戶購物車中的商品與服務器自動同步。與服務器同步購物車可確保客戶不會丟失購物車中的物品。",
    do_not_sync: "关闭同步",
    sync_always: "实时同步",
    sync_every_x_sec: "每隔x秒同步",
    categories: "分类目录",
    category_ids: "分类目录",
    store_switcher: "切换商家",
    publish_manager: "发布管理",
    last_published: "最近发布",
    last_updated: "最近更新",
    published: "已发布",
    out_sync: "不同步",
    in_sync: "已同步",
    current_status: "发布现状",
    not_published: "尚未发布",
    publish_confirm: "当前在线商店的记录在后台和客户端之间不同步，您现在希望现在进行同步吗？",
    jan: "1月",
    feb: "2月",
    mar: "3月",
    apr: "4月",
    may: "5月",
    jun: "6月",
    jul: "7月",
    aug: "8月",
    sep: "9月",
    oct: "10月",
    nov: "11月",
    dec: "12月",
    assign_items: "分配产品",
    categories_desc: "分类目录可以用来组织和安排您的产品，反应在销售报表上和将订单分类打印到不同打印机上。",
    add_category: "添加目录",
    assign_items_to: "指定产品到目录：{category}",
    pickup_delivery: "自取及本地配送设置",
    assign_items_desc: "当前在别的目录下的产品将被重新分配到这个目录下。{count} 个产品将会被分配到目录: {category} 。",
    type_to_search: "输入要搜索的内容...",
    rename: "修改名称",
    confirm_delete: "确认删除",
    are_you_sure_delete_category: "您确定要删除目录：{category} 吗？",
    renaming_category: "修改名称：{category}",
    surcharge_settings: "附加费设置",
    surcharge_settings_description:
        "系统可以在结帐时自动将预定义的附加费应用于每个订单。附加费通常用于支付处理订单所产生的费用。",
    no_tax_goods: "免税商品",
    food: "食品",
    gst_only: "只收GST",
    GST: "GST",
    shipping: "配送方式",
    taxable_goods: "加税商品",
    category_title: "分类目录名称",
    time_code_setting: "可用时间设置",
    add_edit_category: "添加/编辑分类目录",
    edit_category: "编辑分类目录",
    parent_category: "父类别",
    paypal_account_setting: "PayPal账户设置",
    paypal_payment_pro_tip:
        "在您的电商网站上使用Paypal Payment Pro 来接受信用卡付款，您的客户在结账时只需要输入信用卡号码。",
    paypal_express_checkout: "PayPal Express账户设置",
    paypal_express_checkout_tip:
        "在您的结账页面上增加'Paypal Express Checkout' 按钮，吸引众多喜欢用Paypal Express支付的消费者。",
    stripe_account_setting: "Stripe账户设置",
    stripe_account_setting_tip:
        "在您的电商网站上使用Stripe Payment来接受信用卡付款，您的客户在结账时只需要输入信用卡号码。",
    alipay_account_setting: "支付宝",
    braintree_setting: "BrainTree付款",
    braintree_setting_tip:
        "一次性无缝集成即可支持PayPal，Venmo(仅在美国), 信用卡和借记卡以及流行的数字钱包(如Apple Pay）的平台，吸引更多买家并推动更高的转化率。",
    emt: "电子转账",
    emt_desc: "允许您的客户先下订单，然后使用电子或邮件转账向您付款。",
    emt_account: "电子或邮件转账账户",
    emt_password: "邮件转账的密码(可选)",
    emt_password_hint:
        "如果您的 EMT 账户未启用自动存款，请定义一个密码，以便我们的系统提醒您的客户用指定的支付密码付款。",
    card_present_setting: "持卡支付",
    card_present_setting_tip: "持卡支付是客户用信用卡或借记卡在支付POS机上完成交易的支付方式。",
    vendor: "供应商",
    integration_type: "Integration Type",
    access_token: "Access Token",
    device_ids: "Device ID(s)",
    pos_id: "POS ID",
    customer_ids: "Customer ID(s)",
    debug: "Debug",
    clover: "Clover",
    global_payment: "Global Payment",
    moneris: "Moneris",
    rest_pay_api: "REST Pay API",
    remote_pay_sdk: "Remote Pay SDK",
    public_key: "公钥",
    private_key: "私钥",
    merchant_name: "商家名称",
    merchant_contact_number: "商家联系号码",
    partner: "Partner",
    merchant_login: "商家登录",
    api_username: "API用户名",
    api_password: "API密码",
    payment_action: "支付行动",
    authorization: "授权",
    allowed_credit_card_types: "允许使用的信用卡种类",
    mastercard: "MasterCard",
    discover: "Discover",
    switch_maestro: "Switch/Maestro",
    solo: "Solo",
    enable_this_pay_option: "啟用此支付方式",
    title: "标题",
    api_key: "API Key",
    merchant_id: "商户代码",
    credential: "密钥代码",
    alipay_exclusive_account: "支付宝平台独享账户",
    authorize_only: "只授权",
    authorize_and_capture: "授权并且获取",
    wechat_account_setting: "微信账户设置",
    app_id: "App ID",
    discount_information: "折扣信息",
    store_announcement: "商家公告",
    store_account_information: "商家价格计划:",
    onepage: "单页计划",
    free_trial: "免费试用",
    basic: "基本设置",
    bronze: "青铜计划",
    silver: "白银计划",
    gold: "黄金计划",
    platinum: "白金计划",
    credit_card_tip:
        "要允许您的客户使用信用卡付款，请打开此开关；如果您的在线商店上的需要让客户实时信用卡付款，请申请一个PayPal Pro帐户，并启用该服务。",
    require_bill_address: "要求帐单地址",
    require_bill_address_tip: "用户使用信用卡付款时需要帐单地址。",
    pay_later_tip: "如果要允许客户先下订单然后再当面付款，请打开此开关。",
    cash_tip: "如果要允许客户先下订单然后再当面现金付款，请打开此开关。",
    virtual_terminal_tip:
        "虚拟支付终端是允许您的客户扫描提供的二维码在他们自己的手机上完成支付，为您的收银员节省时间。",
    alipay_account_tip:
        "支付宝是全球第一大移动支付服务平台。 要为您的在线商店开通AliPay在线支付，请申请一个在线支付帐户，填写账户信息，然后启用此支付。",
    wechat_account_desc:
        "WeChat is the largest social media and messaging platform in the world. To support WeChat Pay online payment for your online store, please apply for an online payment account, fill in the credentials, and enable it.",
    unionpay_credit_desc:
        "UnionPay is China’s top merchant card service provider. To support UnionPay credit card online payment for your online store, please apply for an online payment account, fill in the credentials, and enable it. Please note, no password is required for the customer to make payment with a UnionPay credit card.",
    unionpay_debit_desc:
        "UnionPay debit card online payment requires user to input password for the payment. To support UnionPay debit card online payment for your online store, please apply for an online payment account, fill in the credentials, and enable it.",
    time_code_setting_desc:
        "时间设置定义了何时可以订购产品。产品上的时间设置优先于类别上的时间设置；如果您不需要特定的产品时间，请保留A",
    show_time_table: "显示时间表",
    show_items: "显示产品",
    hide_items: "隐藏",
    content_language: "内容语言",
    language_select_tip: "您可以在此菜单中找到已本地化的语言。",
    hide_time_table: " 隐藏时间表",
    discount_options: "折扣设置",
    off_l: "关",
    code_exists: "此代码已使用",
    use_unique_code: "请用一个唯一的代码",
    min_purchase: "最少购买金额",
    entire_cart: "整个购物车",
    select_products: "指定产品",
    any_product: "全部产品",
    select_product_categories: "指定分类目录",
    use_per_cust: "每个客户可用次数",
    duplicate: "创建拷贝",
    share_facebook: "通过脸书共享",
    share_twitter: "通过推特共享",
    share_email: "通过邮件共享",
    share_wechat: "通过微信共享",
    search_dot: "搜索...",
    create_disc_code: "创建折扣码",
    discount_codes: "折扣码",
    auto_discounts: "自动折扣",
    free_shipping_rule: "免邮费规则",
    filters: "删选器",
    filter_discount_codes: "删选折扣码",
    sort: "排序",
    sort_by: "排序",
    ascending: "升序",
    descending: "降序",
    newest_first: "最新在前",
    oldest_first: "最旧在前",
    low_to_high: "从低到高",
    high_to_low: "从高到低",
    a_to_z: "A-Z",
    z_to_a: "Z-A",
    item_name: "产品名称",
    discount_to: "最大折扣",
    discount_from: "最小折扣",
    discount_type: "折扣类型",
    discount_type_tip:
        "通过从原始价格中减去一个百分比来折扣项目 折扣适用于购物车中的每个合格项目，例如：输入10的折扣金额 - 折扣适用于购物车中的每个合格项目，例如：在折扣金额中输入10 更换价格比原始价格低10美元. - 通过从购物车总额中减去百分比来折扣整个购物车 在折扣金额中输入10以减去10％ 的总价",
    by_percent: "产品价格折扣的百分比",
    by_fixed: "每个产品的固定金额折扣",
    cart_fixed: "整个订单的固定金额折扣",
    discount_range: "折扣范围",
    date_start: "开始时间",
    date_expire: "过期日",
    apply_filters: "应用筛选器",
    discount_name: "折扣名称",
    create_discount_code: "创建新折扣码",
    discount_code: "折扣代码",
    created_at: "创建时间",
    updated_at: "最近更改时间",
    discount_info: "折扣信息",
    publicly_available: "公开发布",
    enable_product: "启用产品",
    uses_per_customer: "每个客户可用次数",
    use_per_cust_tip:
        "确定属于任何所选客户群的同一注册客户可以使用优惠券代码的次数，该设置不适用于作为没有登陆的客户群的成员的客人购物者，或对于没有登录账户的客户，如果没有限制，请将该字段留空",
    uses_per_coupon: "每张优惠券试用",
    use_per_coup_tip: "确定可以使用优惠券代码的次数，如果没有限制，请将该字段留空",
    min_order_amount: "最少订单金额限制",
    min_amount_tip: "使用此优惠券的最低订单金额限制",
    discount_amount: "折扣值",
    discount_rule: "折扣规则",
    apply_to_all: "应用到所有",
    by_products: "根据产品",
    by_categories: "根据分类",
    save_discount_code: "保存折扣码",
    apply_to_categories: "应用到选择的目录",
    apply_to_products: "应用到选择的商",
    choose_products: "选择产品",
    please_ensure_fill_all_required: "请确认填写所有必须的栏目",
    delete_discount_code: "删除折扣码",
    are_you_sure: "提醒：你确定吗?",
    delete_draft_confirmation: "您确定要删除保存的临时订单吗?",
    edit_discount_code: "编辑折扣码",
    when_exceeding_distance_limit: "当超过距离限制时：",
    free_shipping_within_x: "在 {x} 公里内免费送货； 超过距离收取 {x} km以上的配送费",
    charge_full_delivery_fee: "收取全额配送费",
    transaction_type: "交易类型",
    sku: "SKU",
    sku_long: "SKU (Stock Keeping Unit)",
    category: " 分类目录",
    price: "价格",
    item_list: "产品列表",
    bulk_edit: "批量更改",
    create_item: "创建产品",
    available: "有效",
    sold_out_today: "今天售磬",
    sold_out_indefinitely: "永久售磬",
    normal_product: "普通产品",
    gift_card: "礼品卡",
    self_input: "自助输入价格",
    call_vendor: "联系商家",
    group_purchase_product: "群接龙产品",
    items_filter: "产品删选器",
    item: "产品",
    item_s: "产品",
    price_from: "价格从",
    price_to: "价格至",
    quantity_from: "库存从",
    quantity_to: "库存至",
    apply: "应用",
    reset_filters: "重置删选器",
    item_images: "产品图片",
    item_images_desc: "单击缩略图以查看图片。将图片拖放到最左边可设置为产品封面图片。",
    short_description: "简介",
    description: "详细介绍",
    item_type: "产品类型",
    item_title: "产品名称",
    show_store_link: "显示店铺链接",
    store_link_tip: "打开开关以在商品详细信息页面上显示您店铺的链接。关闭以从页面中删除您的店铺的链接。",
    hide_on_frontend: "在客户端隐藏",
    is_hidden_tip: "打开开关可在客户端隐藏本产品。接龙产品内仍可看到隐藏的产品。",
    max_sale_qty: "每单限购量",
    max_sale_qty_tip: "客户可以在一个订单中可购买的最大数量。如果没有限制请设为0。",
    min_sale_qty: "最小起订量",
    min_sale_qty_tip: "购买此商品时客户必须订购的最低数量。",
    special_price: "特价",
    special_from_date: "特价开始时间",
    special_to_date: "特价结束时间",
    price_margin: "利润率",
    price_cost: "成本",
    price_profit: "利润",
    barcode: "条形码 (ISBN, UPC, GTIN 等.)",
    barcode_short: "条形码",
    barcode_length_invalid: "条形码需在{minlen}到{maxlen}个字符长。",
    barcode_already_in_use: "条形码已被使用",
    barcode_not_unique: "此条码已被其他产品使用",
    click_to_validate_barcode: "点击验证条形码",
    validated: "已验证",
    tax_class: "税率",
    stock: "库存",
    weight: "重量",
    start_date: "开始时间",
    end_date: "结束时间",
    created_date: "创建时间",
    available_time: "有效时间",
    item_info: "产品信息",
    options: "选项",
    preview_video: "预览视频",
    video: "视频",
    drop_down: "下拉框",
    radio: "单选按钮",
    checkbox: "复选框",
    multiple: "多选按钮",
    option_title: "选项标题",
    option_type: "选项种类",
    min_select: "最少选择数量",
    max_select: "最多选择数量",
    quantity_input: "允许输入选项值的数量",
    add_edit_option: "添加/编辑选项",
    add_option: "添加选项值",
    save_option: "保存选项",
    modify_options: "修改选项",
    remove_from_item: "从产品里删除",
    related_upsell_products: "相关产品和加售产品",
    fullfilment: "处理",
    related_products: "相关产品",
    related_products_desc: "看到此产品的客户还将看到相关产品列表。",
    up_sell_products: "加售产品",
    up_sell_products_desc:
        "加售是商家提高收入和利润的常见策略。通常，您可以把客户可能会购买的相关产品作为加销售产品。这样子客户可以在结帐之前将这些产品添加到购物车中。",
    delivery_discount: "配送 折扣",
    pickup_discount: "自取 折扣",
    new_user_discount: "新用户折扣",
    minimum_amount: "最少金额",
    discount_condition: "折扣条件",
    exclude: "排除",
    delete_delivery_discount: "删除配送折扣",
    add_rule: "增加条件",
    exclude_selected_products: "排除指定目录",
    exclude_selected_categories: "排除指定产品",
    apply_selected_products: "应用于选定的产品",
    apply_selected_categories: "应用于选定的分类目录",
    include_selected_products: "包括选定的产品",
    include_selected_categories: "包括所选类别",
    percent: "百分比",
    amount: "金额",
    create_automatic_delivery_discount: "创建配送折扣规则",
    create_automatic_pickup_discount: "创建自取折扣规则",
    create_automatic_shipping_discount: "差距配送折扣规则",
    create_automatic_user_discount: "差距用户折扣规则",
    save_auto_discount: "保存折扣",
    update_discount: "更新条件",
    delivery_discounts: "配送 折扣",
    before_tax: "税前",
    max_distance: "最大免费配送距离",
    when_exceeding_max_distance: "当超过距离限制时",
    distance: "距离",
    free_within: "免费配送距离",
    charge_over: "超过距离后收取配送费",
    user_reward_points: "新用户奖励积分",
    user_discount: "新用户折扣",
    condition_type: "条件种类",
    user_discounts: "用户折扣",
    shipping_discounts: "配送折扣",
    apply_to: "应用到",
    selected_products: "选择产品",
    selected_categories: "选择分类目录",
    delete_user_account: "删除用户帐户",
    include: "包括",
    delete_discount_rule: "删除折扣规则",
    store_qr_code: "扫码下单",
    would_share: "希望为您提供一个",
    off_discount_coupon: "折扣的优惠券代码",
    may_store: "您可以用它来订购",
    no_discount_codes: "没有找到折扣代码",
    create_new_code_option: "点击'创建折扣码'按钮创建一个新的折扣码",
    no_discount_rules: "没有找到折扣规则",
    create_new_rule: "点击'创建折扣规则'按钮创建一个新的规则",
    min_amount_missing: "缺最少金额",
    discount_missing: "缺折扣栏目",
    name_missing: "缺名称",
    missing_from_date: "缺起送日期",
    missing_to_date: "缺结束日期",
    distance_missing: "缺距离",
    missing_min_order: "缺最少订单金额",
    products_missing: "您没有选择产品",
    categories_missing: "您没有选择分类目录",
    category_title_empty_warning: "分类名称不能为空。",
    used: "已用",
    pickup_discounts: "自取折扣",
    required_fields_warn: "带 “*” 标志的为必须栏目",
    field_required: "此字段是必需的",
    invalid_discount_between: "折扣值无效。折扣值应大于{dollar}{gt}{percent}且小于{dollar}{lt}{percent}。",
    invalid_discount_low: "折扣值无效。折扣值应大于{dollar}{gt}{percent}。",
    invalid_discount_high: "折扣值无效。折扣值应小于{dollar}{lt}{percent}。",
    discount_options_description:
        "折扣代码一般是用于给指定的用户提供折扣，使用折扣代码可在结帐时获得对应的折扣；自动折扣适用于所有客户，只要客户订单符合定义的折扣规则即可自动获得折扣。",
    order_ahead_days: "允許的預訂天數",
    order_ahead_days_tip: "“允許的預訂天數“定義了客戶可以提前多少天進行預訂。",
    notification_settings: "通知设置",
    menu_desc: "如果您的商店中有多个菜单，您可以选择相应的菜单，并允许您的客户在不同的菜单之间切换下单",
    add_new: "添加",
    available_time_setting: "有效時間設置",
    edit_menu: "編輯菜單",
    create_menu: "創建菜單",
    save_menu: "保存菜單",
    delete_menu: "刪除菜單",
    please_fill_all_fields: "请填写必须的栏目",
    missing_name: "Missing Name",
    managed_stores: "多分店订单管理",
    managed_stores_desc: "总店经理可以管理多分店的订单，请选择您想管理的分店。",
    managed_stores_description:
        "如果您要同时管理多个分店的订单，可以选择您要的分店。只有有权访问多个分店的管理员用户才能访问此页面。",
    arrange_products: "更改产品显示顺序",
    arranging_category: "更改{category}下的显示顺序",
    drag_drop_products: "拖放产品以更改显示顺序。",
    add_edit_item: "添加/编辑产品",
    option_title_desc: "这是该选项的名称",
    option_type_desc: "这是您的可选项在App或网站上的显示方式。",
    quantity_input_desc: "当启用后，客户就可以在商家页面输入选项值的数量",
    min_select_desc: "如果此选项为可选项，请设置为0，数字表明客户必須选择的最少的数量",
    option_table_header_price_desc: "在产品原价基础上的变化 (例如: 2 = 比原价多$2, -4 = 比原价少$4, 0 = 和原价一样)",
    category_deleted: "分类目录已成功删除",
    product_updated: "产品信息已更新，如果要在您的在线商店中体现此更新，请重新发布。",
    product_created: "新产品已创建，如果要在您的在线商店中显示此产品，请重新发布。",
    nm_placeholder: "请输入选项名称",
    share: "分享",
    share_product: "分享产品",
    product_url_copied: "产品已经拷贝到剪贴板",
    admin_users: "管理员帐户",
    admin_users_desc: "您可以为每个员工设置帐户及相应的权限，每个员工只能看到所需的内容。",
    active: "活动",
    inactive: "冻结",
    admin_role: "管理员角色",
    user_since: "登记时间",
    id: "ID",
    add_admin: "添加管理员",
    personal_information: "个人信息",
    country_code: "国家代码",
    promo_code: "促销代码",
    registration_time: "注册时间",
    wechat_bind: "微信绑定",
    permission_set: "权限设置",
    reset_password: "更改密码",
    please_enter_new_password: "请输入新密码",
    password_length_check: "密码必须至少有6个字符长",
    binded_user_id: "已绑定的用户ID",
    permissions: "权限",
    permission_role: "权限/角色",
    current_store: "现在的商店",
    contact_details: "联系信息",
    contact_details_description:
        "所有授权的用户都需要使用验证码来验证自己的身份，该验证码将通过以下提供的电话号码或电子邮件发送",
    change_password: "更改密码",
    role: "角色",
    ordering_food: "订单",
    edit_order: "修改订单",
    payment: "支付",
    report: "报表",
    edit_personal_information: "编辑个人信息",
    edit_permissions: "编辑权限",
    deactivate_account: "停用帐户",
    cannot_delete_user: "无法删除用户",
    cannot_delete_own_account: "您不能删除自己的帐户",
    delete_user: "删除用户",
    edit_account: "编辑帐户",
    add_admin_user: "添加管理员用户",
    activate_account: "激活帐户",
    read_write: "读/写",
    read_only: "只读",
    wechat_step_one: "步骤1：关注Goopter 微信公众号",
    wechat_step_two: "步骤2： 使用微信扫描下面的二维码订阅微信订单通知服务",
    cannot_access: "无法访问",
    no_inactive_users: "没有已停用户",
    common: "Common",
    settings: "设置",
    user_profile: "用户个人资料",
    public_notice: "公告",
    products_categories: "产品/分类目录",
    order_management: "订单管理",
    marketing: "市场营销",
    store_info_review: "商家信息/评论",
    setting_general_info: "通用信息设置",
    product_review: "产品评论",
    store_review: "对商家评论",
    customer_review: "客户评论",
    create_store: "创建商铺",
    setting_pickup_delivery: "自取及本地配送设置",
    setting_self_serve: "自助下单设置",
    setting_hours: "营业时间设置",
    setting_managed_stores: "管理分店设置",
    setting_order_notifications: "订单通知设置",
    setting_payments: "支付设置",
    setting_surcharge: "附加费设置",
    setting_item_availability: "产品可用性设置",
    setting_switch_store: "切换分店设置",
    setting_menu: "菜单设置",
    display_settings: "网站显示设置",
    roles_permission_settings: "角色/权限设置",
    order_comments: "订单备注",
    no_comments_message: "没有订单备注",
    added_order_comment: "添加订单备注",
    order_history: "订单历史",
    order_export: "导出订单",
    product_availability: "产品可用性",
    store_availability: "商家在线状态",
    category_management: "分类目录管理",
    product_management: "产品管理",
    giftcard_management: "礼品卡管理",
    coupon_management: "优惠券管理",
    setting_discount: "折扣设置",
    referral_page: "推荐统计/管理",
    product_duplicated: "产品已复制",
    product_duplicated_message: "新产品已创建，新检查，进行必要的修改后保存。",
    share_qrcode: "二维码",
    share_qrcode_instant: "二维码 (即刻结帐)",
    regular_product_qr: "产品标准二维码",
    instant_co_qr: "即刻结帐二维码",
    user_input: "用户输入",
    ignore_conf_on_checkout: "结帐时无需确认",
    poster: "海报",
    download_poster: "保存海报",
    customers: "客户列表",
    canada_phone: "加拿大 +1",
    usa_phone: "美国 +1",
    full_name: "全名",
    number_of_orders: "订单总数",
    points_balance: "积分余额",
    total_order_amount: "订单总计",
    last_order_date_range: "最近订单日期范围",
    customer_phone: "客户电话",
    customer_email: "客户电子邮件",
    total_spent: "总金额",
    total_number_orders: "订单总数",
    customer_filter: "客户过滤器",
    send_message: "发送消息",
    view_orders: "查看订单",
    send_coupon: "发送优惠券",
    view_giftcard: "查看礼品卡",
    send_message_to_customers: "向客户发送消息",
    send_to: "发送给",
    message_type: "消息类型",
    coupon_promotion: "优惠券促销",
    store_promotion: "商家促销",
    product_promotion: "产品促销",
    body: "内容",
    choose_product: "选择产品",
    message_sent: "消息发送成功！",
    amt_to: "价格至",
    amt_from: "价格从",
    qty_to: "数量至",
    qty_from: "数量从",
    date_range: "日期范围",
    select_type: "选择类型",
    any_date: "任何日期",
    custom_range: "自定义范围",
    last_order_date_from: "最近订单日期自",
    last_order_date_to: "最近订单日期至",
    last_order_date: "最后订单日期",
    copy_product_url: "拷贝产品链接",
    copy_store_url: "拷贝商家链接",
    open_product_webpage: "打开产品网页",
    admin_user: "管理员用户",
    no_customers: "没有客户",
    see_selected: "查看所选",
    hide_selected: "隐藏所选",
    select_coupon: "选择优惠券",
    choose_product_desc: "请选择促销产品发送给您的客户。",
    missing_x: "缺少{x}",
    reviews: "评论",
    show_selected: "显示所选内容",
    count: "数量",
    balance: "余额",
    reply: "回复",
    user_didnt_leave_rating: "用户未写评论，仅留下了评分。",
    anonymous: "匿名",
    send: "发送",
    reply_sent: "成功发送回复！",
    approved: "已批准",
    unapproved: "驳回",
    approve: "已批准",
    unapprove: "驳回",
    review_approval_updated: "评论批准已更新！",
    customer_id: "客户ID",
    product_id: "产品ID",
    review_date: "评论日期",
    review_update_date: "评论更新日期",
    no_reviews: "无评论",
    search_by_name: "按客户名称搜索...",
    dt_start: "开始日期",
    dt_end: "截止日期",
    update_dt_start: "起始更新日期",
    update_dt_end: "截止更新日期",
    error_email_taken: "该电子邮件已用，请使用另一个邮件地址",
    error_username_taken: "该用户名已用，请使用另一个",
    error_please_try_again: "发生错误，请重试",
    local_delivery: "本地配送",
    order_review: "订单评论",
    change_phone: "更改电话",
    select_option: "选择选项",
    send_verification_code: "发送验证码",
    enter_verification_code: "输入验证码",
    submit_verification_code: "提交验证码",
    phone_number_changed: "电话号码更新成功！",
    phone_number_required: "电话号码必填",
    phone_number_length_invalid: "电话号码长度必须在{minlen}到{maxlen}位之间",
    add_item: "添加产品",
    edit_item: "编辑产品",
    website_url: "桌面Web版的域名",
    mobile_url: "移动Web版的域名",
    website_information: "域名",
    download_QR: "Download QR",
    error_message_product: '保存此产品时发生以下错误: "{message}"',
    use_category_time_setting: "使用分类目录上的时间设置",
    store_url_copied: "成功复制商家链接！",
    verification_code_sent: "验证码已成功发送！",
    password_changed: "密码更新成功！",
    phone_not_registered: "发生错误，该电话号码尚未注册",
    vc_expired: "发生错误，验证码已过期",
    too_many_vc_requests: "发生错误，验证码请求次数太多",
    customer_aready_exists: "发生错误，该客户已存在",
    an_error_occurred: "发生错误，请重试。",
    third_party_courier_restaurant_error_message:
        "更改商铺类型失败。在将商铺类型更改为餐厅之前，请先在设置 -> 自取/配送中更新配送为非“第三方快递。",
    no_items: "没有产品",
    no_active_users: "没有活跃用户",
    min_placeholder: "最小",
    max_placeholder: "最大",
    table_rate: "阶梯式邮费",
    flat_rate: "平价邮费",
    ups: "UPS",
    fedex: "Fedex",
    c2c: "国到国",
    Dashboard: "控制面板",
    third_party_local_delivery_service: "第三方本地配送服务",
    admin_profile: "管理员个人资料",
    admin_user_delete_error: "删除管理员用户时发生错误",
    admin_user_delete_perm_error: "您没有删除此管理员用户所必需的权限。",
    ensure_password_six: "密码必须至少有6个字符长",
    admin_already_exists: "您不能使用此电话号码，因为另一个用户已经在使用此号码。",
    referral_settings: "推荐设置",
    referral_settings_description:
        "推荐计划是一种增长型营销策略，旨在鼓励现有客户向他们的朋友，家人和同事推荐您的商店或产品。",
    allow_cust_refs: "允许客户推荐",
    allow_cust_refs_desc:
        "客户推荐会在被推荐人完成首次注册或登录网站后自动加到推荐人的名下；推荐人将从被推荐人完成的每个订单中获得奖励积分。这是一个长期奖励规则。",
    allow_order_refs: "允许推荐产品或商家",
    allow_order_refs_desc:
        "推荐人将产品或商家网站推荐给被推荐人后，推荐人可在被推荐人完成订单后获得奖励积分。一次性奖励",
    batch_process_referral_rewards: "以批处理方式在一天结束时计算推荐奖励积分",
    batch_process_referral_rewards_desc:
        "打开批处理后，仅在一天的结束时，由系统的批处理模块计算推荐奖励积分并将其分配给每个推荐人；当关闭批处理模式后，推荐人将在订单完成后马上获得推荐奖励积分。批处理模式可减少因为发生退单/退款引起的回撤积分的问题。",
    ref_expiry_days: "推荐有效期限",
    ref_expiry_days_desc: "推荐代码在被推荐人设备上保留的天数。",
    cust_val_points: "客户推荐奖励积分比率",
    cust_val_points_desc:
        "推荐客户可获得的积分比例。例如，如果此值为10，则推介的客户每花$1，推荐人将获得10个奖励积分。",
    ord_val_points: "推荐产品/网站的奖励积分比例",
    ord_val_points_desc:
        "推荐产品或者网站可获得的积分比例。例如，如果此值为10，则推介的客户每花$1，推荐人将获得10个奖励积分。",
    auto_generate_referral_code: "自动生成推荐代码",
    auto_generate_referral_code_desc: "打开开关即可启用为每个新注册的用户自动生成推荐代码。",
    click_open_product_page: "点击打开产品网页",
    generate_referral_code: "生成代码",
    referral_code: "推荐码",
    referral_code_enabled: "推荐代码已成功启用！",
    referral_code_disabled: "推荐代码已成功禁用！",
    referral_code_generated: "推荐代码已成功生成！",
    referral_code_generated_error: "生成推荐代码时出错，请重试",
    referral_code_deleted: "推荐代码已成功删除！",
    referral_code_cannot_delete: "您不能删除此推荐代码，因为尚未兑换所有奖励积分。",
    pid: "产品ID",
    "24_hours": "24小时",
    bulk_actions: "批量操作",
    notifications_settings_changed: "通知设置已成功更新！",
    x_field_not_long_enough: " {x}不够长",
    please_ensure_field_x_long: "请确保它的长度为{x}个字符",
    copy_referral_code: "复制引荐代码",
    referral_code_copied: "成功复制了推荐代码！",
    generate_referrals: "生成推荐",
    enable_referrals: "启用推荐",
    disable_referrals: "禁用推荐",
    delete_referrals: "删除推荐",
    live_mode: "生产服务器模式",
    auto_create_delivery_task: "根据新订单自动创建配送任务",
    service_provider: "服务提供商",
    test_connection: "测试连接",
    delivery_connection_success: "您的第三方本地配送服务连接成功！",
    delivery_connection_failure: "抱歉，您的第三方本地配送服务连接失败",
    third_party_delivery_settings: "第三方本地配送服务设置",
    need_atleast_address_for_multiple: '您需要至少有一个提货点才能启用"多个提货点"功能。',
    need_atleast_address_for_multiple_delivery: "您需要至少有一个配送区域才能启用“基于地区的固定配送费“功能。",
    ship_to: "配送国家",
    all_countries: "所有国家",
    specific_countries: "指定的国家",
    allowed_countries: "允许的国家",
    handling_fee: "手续费",
    region: "区域",
    postal: "邮政编码",
    no_rules: "没有规则",
    add_table_rate_rule: "添加平价配送规则",
    base_price: "基本价格",
    per_order: "每件订单",
    per_item: "每件产品",
    handle_type: "处理类型",
    deleted_successfully: "成功删除",
    enter_address_to_autofill: "这里输入地址可自动填写地址",
    calc_handling_fee: "计算手续费",
    percentage: "百分比",
    dollar: "金额",
    fixed: "固定",
    delete_rule: "删除规则",
    edit_rule: "编辑规则",
    shipping_settings: "配送设置",
    regular_shipping: "普通配送",
    express_shipping: "快速配送",
    min_shipping_days: "最短配送天数",
    max_shipping_days: "最长配送天数",
    user_id: "用户ID",
    access_key: "访问秘钥",
    container: "容器",
    pickup_method: "取货方式",
    apply_handling: "处理应用",
    weight_unit: "重量单位",
    max_weight: "最大重量",
    min_weight: "最小重量",
    destination_type: "地址种类",
    package_request_type: "包裹要求种类",
    allowed_methods: "允许的方法",
    customer_packaging: "客户自包装",
    ups_letter_envelope: " UPS信件信封",
    customer_supplied_package: "客户提供的包裹",
    ups_tube: "UPS Tube",
    pak: "PAK",
    ups_express_box: "UPS Express Box",
    ups_worldwide_25: "UPS Worldwide 25 Kilo",
    ups_worldwide_10: "UPS Worldwide 10 Kilo",
    pallet: "Pallet",
    small_exp_box: "Small Express Box",
    medium_exp_box: "Medium Express Box",
    large_exp_box: "Large Express Box",
    regular_daily_pickup: "Regular Daily Pickup",
    on_call_air: "On Call Air",
    one_time_pickup: "One Time Pickup",
    letter_center: "Letter Center",
    customer_counter: "Customer Counter",
    ups_express: "UPS Express",
    ups_expedited: "UPS Expedited",
    ups_worldwide_express: "UPS Worldwide Express",
    ups_worldwide_expedited: "UPS Worldwide Expedited",
    ups_standard: "UPS Standard",
    ups_three_day_select: "UPS Three-Day Select",
    ups_early_am: "UPS Express Early A.M.",
    ups_saver: "UPS Saver",
    divide_equal: "Divide to Equal Weight (one request)",
    origin_weight: "原始重量",
    account_key: "账号",
    packaging: "打包",
    dropoff: "送货",
    is_residential_delivery: "住宅交货",
    meter_number: "米数",
    fedex_envelope: "Fedex Envelope",
    fedex_pak: "Fedex Pak",
    fedex_box: "Fedex Box",
    fedex_tube: "Fedex Tube",
    fedex_10_box: "Fedex 10kg Box",
    fedex_25_box: "Fedex 25kg Box",
    your_packaging: "Your Packaging",
    regular_pickup: "Regular Pickup",
    request_courier: "Request Courier",
    drop_box: "Drop Box",
    business_service_center: "Business Service Center",
    europe_national_priority: "Europe First Priority",
    fedex_1_freight: "1 Day Freight",
    fedex_2_freight: "2 Day Freight",
    fedex_2_day: "2 Day",
    fedex_2_day_am: "2 Day AM",
    fedex_3_day_freight: "3 Day Freight",
    fedex_express_saver: "Express Saver",
    fedex_ground: "Ground",
    first_overnight: "First Overnight",
    ground_home_delivery: "Home Delivery",
    international_economy: "International Economy",
    international_economy_freight: "Inernational Economy Freight",
    international_first: "International First",
    international_ground: "International Ground",
    international_priority: "International Priority",
    international_priority_freight: "International Priority Freight",
    priority_overnight: "Priority Overnight",
    smart_post: "Smart Post",
    standard_overnight: "Standard Overnight",
    fedex_freight: "Freight",
    fedex_national_freight: "National Freight",
    add_c2c_regular_rule: "添加普通跨国配送规则",
    edit_c2c_regular_rule: "编辑普通跨国配送规则",
    add_c2c_express_rule: "添加快捷跨国配送规则",
    edit_c2c_express_rule: "编辑快捷跨国配送规则",
    update_rule: "更改规则",
    united_states: "美国",
    canada: "加拿大",
    united_kingdom: "英国",
    china: "中国",
    edit_table_rate_rule: "编辑阶梯配送费率规则",
    require_utensils: "餐具选项",
    require_utensil_tip: "此选项设置决定您的客户是否可以在结帐页面上看到“需要餐具选项”。",
    print_request_utensil: "收据上打印餐具的选项",
    print_request_utensil_description: "此选项决定餐具要求是否将显示在打印的收据上。",
    do_not_show_option: "不显示这个选项",
    show_utensil_option_not_required: "显示这个选项，默认为不需要餐具",
    show_utensil_option_required: "显示这个选项，默认为需要餐具",
    do_not_print: "不打印",
    always_print: "一直打印",
    only_print_utensil_request: "仅在客户需要餐具时打印",
    only_print_utensil_no_request: "仅在客户不需要餐具时打印",
    all_referrals_already_generated: "所有选定的客户已经生成了推荐码！",
    all_referrals_were_already_enabled: "所有选定客户的推荐码已启用！",
    all_referrals_were_already_disabled: "所有选定客户的推荐码已被禁用！",
    km: "公里",
    miles: "英里",
    distance_unit: "距离单位",
    extra_dollar_per_mile: "超过部分每英里的费用",
    payment_option_disabled_unless_required:
        "T如果您离开此页面，此支付选项将被自动禁用。要保持启用状态，请填写所有必填字段。",
    instore_discount: "店内折扣",
    create_automatic_instore_discount: "创建店内自动折扣规则",
    checkout_settings: "离线支付/匿名下单",
    checkout_settings_description:
        "访客结帐允许客户在不提供客户信息的情况下下订单。通常用于店内自助下单或者扫码支付方式(虚拟POS终端支付)。",
    guest_checkout: "匿名下单",
    offline_payment: "离线支付",
    block_online_payment: "禁用在线支付",
    instant_pay: "数字资产(礼品卡等)",
    no_menus: "没有定义菜单",
    enter_address_here: "请输入地址",
    cut_off_time: "截止时间",
    braintree_allow_paypal: "支持PayPal支付",
    with_min_purchase: "最低购买金额",
    performance: "使用次数",
    format_qr_codes: " 选择二维码风格",
    format_qr_codes_description: "Format QR Codes as you would like them to appear.",
    scan_and_order: "Scan to Order",
    powered_by: "Powered by",
    download_pdf: "下载PDF",
    confirm_format: "继续",
    require_scan_instore: "需要扫码才能允许店内下单",
    qr_code_style: "二维码风格",
    qr_code_type: "二维码类型",
    order_online_earn_points: "在线订购赚取积分",
    a3: "A3",
    a4: "A4",
    squares: "方块",
    dots: "圆点",
    legal: "Legal",
    background_colour: "背景颜色",
    paper_size: "打印纸大小",
    show_rewards_info: "显示积分提示",
    bleeding_margin: "打印边距(毫米)",
    add_margin_between_qrs: "增加二维码之间的间距",
    add_custom_text: "包括自定义标签",
    table_numberz: "桌号",
    parking_lot: "停车位号",
    kiosk_number: "自助终端号码",
    reward_points: "奖励积分",
    reward_points_extra_customer: "您的帐户上的积分余额将在订单完成后更新。",
    reward_points_extra_admin: "客户帐户上的积分余额将在订单完成后更新。",
    default_cc_gateway: "默认信用卡处理网关",
    offline_gateway: "离线处理信用卡",
    paypal: "Paypal",
    stripe: "Stripe",
    not_publicly_available: "Not available to public",
    kgs: "公斤",
    kg: "公斤",
    lbs: "磅",
    lb: "磅",
    oz: "盎司",
    grams: "斤",
    support: "支持",
    generate_qr_setting: "二维码生成器工具",
    generate_qr_setting_description: "制作店铺海报, 或生产您自己的任意二维码",
    qr_code_type_description: "选择二维码风格",
    store_poster: "海报样式",
    arbitrary_qr_code: "自定义样式",
    variables_title: "自定义标签",
    discount_value: "折扣数值",
    order_types_label: "点餐类型",
    bottom_label: "底部标签",
    style_settings: "样式设置",
    url_label: "链接地址",
    payment_icons: "付款图标",
    qr_store_logo: "PNG 格式商家徽标",
    residential: "住宅",
    commercial: "商业",
    "customer_new": "新客户",
    "customer_returning": "老客户",
    "abandoned_carts": " 未完成的购物车",
    "add_customer": "添加客户",
    "new_customer": "新客户",
    "customer_info": "客户信息",
    "subscribe_message": "客户同意接收营销邮件",
    "note": "备注",
    "note_place_holder": "为此客户添加备注",
    "tags": "标签",
    "map_search_placeholder": "输入街道地址",
    "company": "公司",
    "please_input_valid_x": "请输入有效的{x}",
    "customer_since": "自 {time} 注册的客户",
    "customer_note": "客户备注",
    "no_note_added": "还没有备注",
    "last_order": "最近订单",
    "recent_orders": "最近订单",
    "default_address": "默认地址",
    "edit_customer_info": "编辑客户信息",
    "update_customer_success": "更改客户信息成功",
    "edit_address": "编辑地址",
    "default": "默认",
    "set_as_default": "设为默认",
    "modify": "修改",
    "add_address": "添加地址",
    "email_marketing": "市场营销邮件",
    "edit_status": "编辑状态",
    "subscribed": "已订阅",
    "not_subscribed": "未订阅",
    "edit_email_marketing_status": "编辑市场营销邮件订阅状态",
    "edit_email_marketing_status_warning": "在订阅市场营销电子邮件之前，您应该征得客户的同意",
    "subscribed_on_x": "开始订阅时间 {time}",
    "view_all_tags": "查看所有标签",
    "add_tag": "添加标签",
    "reset_password_message": "发送了一个链接到客户的邮箱，供客户重置密码。",
    "sent_reset_link": "已发送链接",
    "update_password": "修改密码",
    "password_confirmation_sent": "已向客户发送确认电子邮件",
    "view_all_records": "查看所有记录",
    "show_last_3_records": "显示最近三条记录",
    "x_gift_card_found": "找到{x}个礼品卡",
    "purchase_date": "下单日期",
    "purchase_date_giftcard": "购买时间",
    "gift_card_code": "礼品卡号码",
    "see_more_orders": "查看更多订单",
    "no_default_address_set": "没有默认地址",
    "no_orders_found": "没有历史订单",
    "customer_email_exists": "提供的电子邮件已被其他客户使用，请使用其它电子邮件。",
    "customer_phone_exists": "提供的电话号码已被其他客户使用，请使用其它号码。",
    "view_detail": "查看详细",
    "preferred_delivery_method": "配送服务提供方",
    "self_local_delivery": "商户自行本地配送",
    "third_party_courier": "第三方快递(UPS, Fedex, 加拿大邮政等)",
    "third_party_local": "第三方本地配送",
    "shipping_fee": "配送费",
    auto_accept_order_settings: "自动接受订单设置",
    auto_accept_order_settings_description:
        "如果您希望系统自动接受新订单而不是手动接受订单，请开启自动接受订单设置。您可以定义自动接受订单的条件。",
    auto_accept_order_enabled: "自动接受订单",
    auto_accept_order_type_any: "所有订单",
    auto_accept_order_type_in_store: "店内订单",
    auto_accept_order_type_pickup: "自取订单",
    auto_accept_order_type_delivery: "配送订单",
    auto_accept_order_type_instant_pay: "扫码支付/其他",
    auto_accept_time_any_time: "任何时间",
    auto_accept_time_store_hours_only: "营业时间内",
    auto_accept_order_time: "下单时间范围",
    auto_accept_time_setting_any: "任何时间",
    ASAP: "ASAP",
    auto_accept_time_setting_asap: "尽快",
    auto_accept_time_setting_same_day: "当天任何时间",
    auto_accept_time_setting_future_day: "未来日期订单",
    auto_accept_time_setting_specify: "具体时间",
    auto_accept_due_time_setting: "要求时间设置",
    auto_accept_due_time_value: "要求时间",
    auto_accept_due_time_within: "范围",
    auto_accept_due_time_hours: "小时",
    auto_accept_due_time_minutes: "分钟",
    auto_accept_due_time_future_day: "未来日期",
    auto_accept_due_time_same_day: "当天",
    auto_accept_order_status: "接受订单后的默认状态",
    auto_accept_payment_type: "支付方式",
    auto_accept_payment_any: "所有方式",
    auto_accept_payment_online: "在线支付",
    auto_accept_payment_offline: "离线支付",
    content_pages: "自定义内容页面",
    content_pages_description: "管理您的在线商店需要的的自定义页面",
    add_content_page: "添加自定义内容页面",
    edit_content_page: "编辑自定义内容页面",
    content: "内容",
    updated_content_page_list_success: "自定义内容页面已成功更新! 要将其反映在您的在线商店中，请重新发布。",
    added_content_page_success: "自定义内容页面创建成功！ 要将其反映在您的在线商店中，请重新发布。",
    updated_content_page_success: "自定义内容页面更新成功！ 要将其反映在您的在线商店中，请重新发布。",
    deleted_content_page_success: "自定义内容页面删除成功！ 要将其反映在您的在线商店中，请重新发布。",
    delete_content_page_unsuccessful: "无法删除自定义内容页面。",
    delete_content_page_confirmation: "您确定要删除此自定义页面吗？",
    duplicate_url_key: "此URL 已在另一个页面上使用，请更改为其它内容。",
    discounted_upsell: "折扣加售",
    amount_type: "金额类型",
    amount_type_tip: "选择触发折扣加售的订单金额类型",
    discounted_upsell_items: "折扣加售产品",
    add_discounted_upsell_rule: "添加折扣加售规则",
    edit_discounted_upsell_rule: "编辑折扣加售规则",
    delete_rule_confirmation: "您确定要删除这个规则吗？",
    grand_total: "订单总额",
    net_amount: "商品税前金额",
    reward_points_settings: "奖励积分设置",
    reward_points_settings_description:
        "设置客户您的商铺中赚取和使用奖励积分的方式。要为您的商店启用奖励积分，请致电 (778)379-7918 联系 Goopter。",
    min_points_redemption_amount: "最低可提取积分的等值金额",
    min_points_redemption_amount_tip:
        "提取积分所需要的最低积分余额的金额。比如，设置为5时，客户需要至少等值$5的积分才能提取积分。",
    max_points_redemption_setting: "最高积分提取设置",
    max_points_redemption_type: "限制类型",
    max_points_redemption_type_tip: "用于确定客户在每个订单中可提取积分的最大值。",
    max_points_redemption_per_order: "每个订单的最大可提取积分百分比/最大可用积分支付的金额",
    max_redemption_percentage_per_order: "每个订单的最大可提取积分百分比",
    max_redemption_amount_per_order: "每个订单最大可用积分支付的金额",
    max_redemption_percentage_per_order_tip:
        "每个订单允许客户可兑换积分的最大百分比。比如设置为10时，客户最多只能用积分兑换其订单金额的10%。",
    max_redemption_amount_per_order_tip:
        "每个订单允许客户提取积分的最大值。比如当设置为10时，客户每笔订单最多可用积分支付$10。",
    no_limit: "没有限制",
    percentage_of_order: "订单百分比",
    dollar_value: "金额",
    max_redemption_warn: "*每个订单允许客户提取积分的最大值必须大于提取积分所需要的最低积分余额的金额。",
    orders: "订单",
    order_id: "订单编号",
    order_list: "订单",
    ord_dt: "下单时间",
    exp_dt: "期待时间",
    ord_total: "订单总价",
    s_method: "订单类型",
    p_method: "支付方式",
    table_number_no_pound_sign: "桌号",
    start_processing_message: "您确认要确认订单吗？",
    mark_as_complete_message: "您确定要把订单标注为完成吗？",
    cancel_order_message: "您确定要取消订单吗？",
    accept: "接单",
    complete: "完成",
    reject: "拒绝",
    refund: "退款",
    print: "打印",
    quick_print: "快速打印",
    //refund
    refund_amount: "退款金额",
    refund_all: "全额退款",
    refund_remark: "备注",
    //dialog
    "edit-shipping-fee-message": "更改运费将导致取消原订单并用修改后的运费创建新订单, 您确定要继续吗？",
    edit_shipping_info: "编辑配送地址",
    additional_info: "额外信息",
    party_size: "就餐人数",
    exp_time: "要求配送时间",
    estimatedDeliTime: "预计配送时间",
    delivery_time: "送达时间",
    pickup_time: "取单时间",
    "create-new-order-message": "修改订单将会取消此订单，并且创建一个新的订单，您确定要修改吗？",
    order_and_account_information: "订单与客户信息",
    address_info: "地址信息",
    billing_address: "账单地址",
    shipping_address: "配送地址",
    delivery_address: "配送地址",
    pickup_location: "自取地址",
    payment_and_shipping_method: "付款和送货方式",
    payment_info: "支付信息",
    shipping_handling_info: "配送信息",
    item_order: "订单商品",
    raw_total: "小计",
    notes_for_this_order: "订单注释",
    alert_on_notification: "发消息时警告",
    sub_comment: "提交评论",
    modify_discount: "修改折扣",
    add_discount: "添加折扣",
    modify_tips: "修改小费",
    add_tips: "添加小费",
    add_comment: "添加备注",
    modify_shipping_fee: "修改配送费",
    edit_payment: "更改支付",
    edit_extra: "修改信息",
    edit_payment_method: "更改支付方式",
    update_payment_method: "更改支付方式",
    "select_receipt(s)_to_print": "选择要打印的收据",
    change_payment_to: "您确定要将付款方式更改为",
    //filter
    order_filter: "订单筛选条件",
    search_order_with: "搜索订单...",
    reject_order: "拒绝订单",
    estimated_ready_time: "预计就绪时间",
    estimated_delivery_time: "预计送达时间",
    enter_reason_for_rejection: "输入拒绝原因",
    ETA: "预计就绪",
    estimated_delivery_short: "预计送达时间",
    req_time_short: "要求時間",
    exp_time_stort: "预计就绪",
    surcharge: "附加費",
    store_surcharge: "附加費",
    order_surcharge: "訂單附加費",
    not_paid: "未付款",
    authorized: "预授权",
    unpaid: "未支付",
    modify_surcharge: "修改附加費",
    add_surcharge: "添加附加費",
    remove_surcharge: "删除附加费",
    remove_surcharge_confirm_message: "您确认要删除这个订单的附加费吗",
    remove_tips: "删除小费",
    remove_tips_confirm_message: "您确认要删除这个订单的小费吗",
    go_to_live_order: "查看未完成订单",
    pending_order_warning: "以下订单尚未接单，",
    pending_print_order_warning: "以下订单尚未打印，",
    handle_asap: "請盡快處理。",
    order_reminder: "订单提醒",
    comment_order_pending: "新订单",
    comment_order_processing: "訂單標記為處理中",
    comment_order_complete: "订单完成",
    comment_order_canceled: "订单取消",
    comment_order_closed: "订单关闭",
    comment_order_accepted: "商家已接单",
    comment_order_being_prepared: "商家准备中",
    comment_order_ready_for_pickup: "订单已经准备好自提",
    comment_order_ready_for_driver: "订单已经准备好配送",
    comment_order_out_for_delivery: "正在配送",
    comment_order_served: "已上菜",
    update_item: "更改产品",
    update_original_item: "更改产品",
    out_of_stock_replace: "替换产品",
    out_of_stock_remove: "移除产品",
    reverse_current_change: "撤回修改",
    customer_request: "客户要求",
    out_of_stock_today: "今日售磬",
    out_of_stock_indefinitely: "永久下架",
    choose_a_reason: "更改的原因：",
    choose_an_action: "请选择一个操作",
    choose_an_item: "选择一个产品",
    update_an_item: "更改产品",
    replace_an_item: "替换产品",
    pick_one: "必选1项",
    pick_amount: "Required, pick {min}",
    pick_range: " 选择 {min}-{max}",
    pick_range_up_to: "可选{max}项",
    pick_range_required: "必选{min}-{max}项 ",
    pick_optional: "可选",
    apply_changes: "最终确认",
    suggest_changes: "发送给客户",
    add_new_item: "增加产品",
    reverse_all_changes: "取消更改",
    remove_x: "移除了产品",
    replace_x_with_y: "更换了产品",
    update_x: "更改了产品",
    add_x: " 增加了产品",
    are_you_sure_reverse_all: "取消更改后将返回到原始订单",
    are_you_sure_send_to_customer: "系统将把订单更改发送给客户。请等待客户确认后再处理订单。",
    are_you_sure_finalize: "您要确定对订单的最终更改吗？在进行此操作之前，请确保已获得客户同意。",
    confirm_cancel_changes: "确认取消更改",
    are_you_sure_reject_all: "您确认要取消此订单吗？",
    continue: "继续",
    no_item: "还没有选择任何商品",
    no_options_available: "没有可用的选项",
    confirm_ready_for_pick_up: "您确定要标记为“自取就绪“吗？",
    confirm_ready_for_delivery: "您确定要标记为“自取就绪“吗？",
    confirm_start_delivery: "您确认要更改状态吗？",
    confirm_start_preparing: "您确认要开始准备这个订单吗？",
    "confirm_merge": "合并订单将创建一个新订单，并将所有未付款订单标记为取消，已付款订单标记为完成。您确定要继续吗？",
    out_of_stock_warning_message: "您选择的产品无货，请在选择产品之前更改其可用性设置。",
    pending_for_customer_confirmation: "等候客户确认",
    order_picked_up: "订单已取",
    start_delivery: "开始配送",
    start_preparing: "开始准备",
    order_start_preparing: "开始准备订单",
    start_preparing_now: "开始准备",
    start_prapring_now_check_box_message: "这是预定的订单，您可以勾选以标记为开始准备。",
    order_canceled: "订单已取消",
    pending_for_store_confirmation: "待商家接单",
    store_accepted: "商家已接单",
    store_is_preparing: "商家准备中",
    ready_for_pick_up: "自取就绪",
    ready_for_delivery: "配送就绪",
    ready_for_courier_pick_up: "等待配送员取单",
    rejected_by_courier: "配送员拒单",
    courier_assigned: "已安排配送员",
    courier_accepted: "配送员接单",
    courier_heading_to_store: "配送员前往商家",
    courier_arrive_at_the_store: "配送员抵达商家",
    courier_collected_the_package: "配送员已取单",
    courier_heading_to_customer: "配送员前往客户处",
    courier_arrive_at_customer: "配送员抵达客户处",
    delivered_order: "配送完成",
    new_comment_for_order: "订单: %s 添加了新的备注: %s",
    category_all: "全部",
    category_new: "新订单 / 待接单",
    category_preparing: "准备中",
    category_ready: "已就绪",
    category_delivering: "配送中",
    category_accepted: "已接单",
    category_scheduled: "预定订单",
    category_completed: "完成",
    category_canceled: "取消/拒絕",
    category_closed: "關閉",
    "category_serve_restaurant": "已上菜",
    "category_serve_others": "已给客户",
    status_new: "新订单",
    status_pending: "待接单",
    status_preparing: "准备中",
    status_ready: "已就绪",
    status_delivering: "配送中",
    status_accepted: "已接单",
    status_scheduled: "预定订单",
    "status_serve_restaurant": "已上菜",
    "status_serve_others": "已给客户",
    search_order: "按名字或号码搜索",
    "search_order_live_order": "按名字, 电话或者桌号搜索",
    view_order_history: "查看历史订单",
    no_order: "没有订单",
    no_selected_order: "没有选中的订单",
    confirm_on_hold: "关闭在线状态将导致无法在您的网站上下订单。 你确定要继续吗？",
    total_item_count: "{total}件商品",
    over_due_x: "逾期%s",
    delivery_location: "区域",
    all_products: "所有产品",
    sold_out_only: "售磬产品",
    available_only: "有效产品",
    advanced_mode: "Advanced Mode",
    assigning_items: "Assigning Items",
    are_you_sure_delete_option: "确定要删除此选项吗？",
    are_you_sure_delete_product: "你确认想删除这个产品吗?",
    are_you_sure_delete_image: "确定要删除此图像吗？",
    are_you_sure_delete_store: "Are you sure you want to delete this store?",
    delete_related_product_warning_message:
        "本商品已设置为以下商品的相关产品。删除此商品会将其从包含它的相关产品列表中删除。{item_list}",
    delete_upsell_product_warning_message:
        "本商品已设置为以下商品的加售产品。删除此商品会将其从包含它的加售产品列表中删除。{item_list}",
    delete_discounted_upsell_product_warning_message:
        "至少在一个折扣加销规则中使用了本产品。删除本产品会将其从所有规则中删除。",
    confirm_online_status: "警告",
    confirm_online_status_content: "关闭在线状态后在线下单将会暂停。您确定要继续吗?",
    export_orders: "导出订单",
    update_order_success: "更改订单成功",
    order_status_updated: "更改订单状态",
    update_suggestions_success: "提交建议成功",
    select_x_items: "选择{x}个产品",
    done_update: "完成更新",
    last_x_days: "过去{x}天",
    custom: "自选",
    clear: "清除",
    item_lan: "产品内容语言",
    export_order_filter_title: "您要导出哪个订单？",
    filter_by_x: "按{x}进行过滤",
    select_group_purchase_product: "选择群接龙",
    sku_prefix: "SKU前缀",
    order_detail: "订单明细",
    order_detail_per_item: "订单明细 (每个产品一条记录)",
    item_detail: "订单商品详细信息",
    item_summary: "订单商品统计信息",
    order_detail_per_order: "订单明细 (每个订单一条记录)",
    all_in_one_report_excel: "Excel 格式的多合一报告",
    export: "导出",
    over_due: "逾期",
    more_actions: "更多操作..",
    more: "更多...",
    no_export_record_found: "未找到可用的导出记录",
    inaccurate_alert_message: "当前的统计数字可能不准确，例如订单数量及总金额。您可以点击刷新图标以获取准确的数字",
    refresh: "刷新",
    order_status: "订单状态",
    bulk_update: "批量更改",
    order_time: "下单时间",
    share_store: "分享商家",
    show_available_only: "仅显示可用产品",
    search_by_item: "按产品搜索",
    require_utensil: "需要餐具",
    no_require_utensil: "无需餐具",
    braintree_payment_error_title: "无法将订单标记为已完成",
    braintree_payment_error_content: "无法完成此订单的付款，请检查订单备注中的原因。",
    keep_order_open: "保持订单现有状态",
    mark_as_complete_and_paid: "将订单标记为完成并已付款",
    mark_as_closed: "将订单标记为关闭 (如果客户已通过新的订单付款)",
    payment_method_change: "更改支付方式",
    cancel_order: "取消订单",
    bulk_update_none_closable_message: "在更新过程中对话框不可关闭。您可以暂停或停止任务，或者等待所有订单处理完毕.",
    processing_orders: "批量更新订单到{status}…",
    resume: "继续",
    pause: "暂停",
    stop: "停止",
    no_change: "没有变化",
    task_complete: "任务完成",
    task_canceled: "任务已取消",
    expected_ready_time: "期待就绪时间",
    commute_time: "交通时间",
    esitmated_arrival: "预计送达时间",
    estimated_ready_pickup_time: "预计自取就绪时间",
    estimated_commute_time: "预计交通时间",
    order_ready_time_setting: "订单就绪时间设置",
    min: "最低",
    max: "最多",
    group_by_table: "按桌分组",
    serve_restaurant: "已上菜",
    serve_others: "已给客户",
    start_serving_message_restaurant: "您确定要将其标记为“已上菜“吗？",
    start_serving_message_other: "您确定要将其标记为“已给客户“吗？",
    merge: "合并",
    group_by_items: "按产品分组",
    payment_transactions: "交易历史",
    transaction_id_title: "交易号",
    transaction_type_title: "交易类型",
    transaction_time_title: "交易时间",
    create_order: "创建订单",
    find_or_create_a_customer: "查找或创建客户",
    create_a_new_customer: "创建新客户",
    delete_customer: "删除客户",
    delete_customer_warning: "您确定要删除此客户吗？",
    no_phone_number: "没有电话号码",
    no_email: "没有电子邮件",
    notes: "备注",
    please_add_some_items: "请添加商品",
    please_choose_a_customer: "请选择客户",
    please_input_shipping_method: "请输入送货方式",
    click_to_input_shipping_addresss: "点击输入配送地址",
    click_to_select_payment_method: "点击选择支付方式",
    please_input_valid_credit_card: "请输入有效的信用卡",
    place_order_with_x: "确认下单 - {x}",
    secure_connection: "信息将通过加密方式安全传送",
    are_you_sure_remove_gift_card: "你确定要删除这张礼品卡？",
    set_giftcard: "选择礼品卡",
    expiry_date: "有效期",
    gift_cards_points_alert: "兑换积分时不能再使用礼品卡; 要使用礼品卡, 请先移除兑换的积分。",
    points_gift_cards_alert_message: "使用礼品卡时就不能再兑换积分; 要兑换积分，请先移除礼品卡。",
    set_points: "點按兌換積分",
    point_balance: "余额: {balance}",
    redeem_points_dialog_title: "兑换积分",
    redeem_x: "兑换: {x}",
    please_input_pickup_time: "请选择自取时间",
    please_input_delivery_time: "请选择配送时间",
    edit_discount: "Edit Discount",
    remove_discount: "Remove Discount",
    reason: "Reason",
    reason_helper: "Your customer can see this reason",
    invalid_input: "Invalid Input",
    item_oos: "抱歉，以下产品缺货: {oos_items}。",
    add_items: "Add Items",
    select_category: "选择目录",
    "drafts": "临时订单",
    cloud_pos: "云POS",
    "others": "Others",
    "deleted_quotes_successfully": "Quote(s) Deleted Successfully!",
    "confirm_delete_quotes": "Are you sure you want to delete these quotes?",
    "confirm_delete_quotes_content": "Are you sure you want to delete the selected {count} quotes?",
    "click_to_choose_a_customer": "点击选择客户",
    selected_item_s: "已选商品",
    export_select: "导出选中的订单",
    export_filter: "导出过滤的订单",
    selected_order_ids: "选中的订单号",
    select_pickup_location: "请选择自取位置",
    select_shipping_options_description: "请选择订单类型",
    no_draft_order: "没有临时订单",
    add_gift_card: "添加礼品卡",
    bookvalue: "账面金额",
    book_value: "账面价值",
    date_created: "已创建",
    name_slash_phone: "客户名/电话",
    confirm_delete_product: "确定要删除此产品吗？",
    no_gift_cards: "没有礼品卡。",
    add_edit_gift_card: "添加/编辑礼品卡",
    prepare_order_threshold: "开始准备订单需要的时间",
    video_desc:
        "要支持产品视频，请先将视频上传到Youtube或Vimeo。在包含视频的Youtube或Vimeo上打开网页，单击“共享“，复制视频URL，然后将该URL粘贴到“视频URL”字段中。",
    giftcard_code: "礼品卡代码",
    customer_name: "客户姓名",
    transaction_history: "交易记录",
    view_transaction_history: "查看交易历史",
    view_points_history: "查看积分历史",
    add_credit: "充值",
    transactions: "交易",
    credit: "信用",
    charge: "扣款",
    order: "订单",
    delta: "变化",
    gift_card_account: "礼品卡账户",
    successfully_added_credit: "已成功将$ {value}添加到了礼品卡帐户。",
    successfully_charged_giftcard: "已成功在礼品卡帐户中扣除$ {value}。",
    insufficient_balance: "余额不足",
    insufficient_balance_message: "您要收取的金额高于该帐户的余额{maxAmountRounded}.",
    hide_zero_balance_giftcard: "隐藏余额为零的记录",
    sales_and_orders: "销售和订单",
    view_controls: "视图设置",
    net_sales: "销售金额",
    total_sales: "总金额",
    total_orders: "总订单量",
    average_order: "订单均价",
    customer_statistics: "客户统计",
    total_customers: "总客户数",
    new_customers: "新客户数",
    ret_customers: "回头客",
    item_sales: "产品销售",
    overview: "总览",
    total_amount: "总金额",
    top_items_by_sale: "最畅销商品",
    least_items_by_sale: "销量最少商品",
    quantity: "数量",
    sold: "已售",
    average_order_price: "Average Order Price",
    shipment_tracking_info: "快递追踪信息",
    carrier: "快递公司",
    select_carrier: "选择快递公司",
    track_number: "运单号码",
    delete_order_tracking_confirmation: "您确定要删除此快递追踪记录吗？",
    duplicate_order_tracking_message: "输入的运单号已存在，请使用其他值重试。",
    notify_customer: "通知客户",
    add_tracking: "添加运单号",
    add_printer: "添加打印机",
    print_test_page: "打印测试页",
    receipt_delete_confirm_title: "确认删除收据",
    //print view
    table_number: "桌号",
    welcome_text1: "感谢您的惠顾",
    welcome_text2: "欢迎在线订购",
    //Common Setting
    beep_on_new_order: "语音提醒打印",
    alert_on_new_order: "提醒打印",
    auto_print: "自动打印",
    beep_on_print: "打印时语音警报",
    sound_alert_on_printer_disconnect: "打印机断开时语音警报",
    //individual
    receipt_name: "收据名称",
    printer_name: "打印机名称",
    enable_printing: "启用打印",
    autoPrint: "自动打印",
    receipt_template: "收据模版",
    show_price: "打印价格",
    split_items: "分单",
    with_QR_code: "在底部打印二维码",
    print_selected_categories_only: "只打印选定的类别",
    add_receipt: "添加收据",
    delete_receipt: "删除收据",
    please_select_printer: "请选择打印机",
    receipt_name_missing: "请添加一个收据名称",
    printer_missing: "请添加打印机",
    primary_lan_missing: "请选择主语言",
    select_printer: "选择打印机",
    save_receipt: "保存收据",
    receipt_list: "收据列表",
    "select-template-placeholder": "选择模板",
    "select-first-language": "选择第一语言",
    "select-second-language": "选择第二语言",
    included_products: "除选定目录外的额外产品",
    included_products_info: "添加需要显示在收据上的其它产品",
    excluded_products: "排除的产品",
    excluded_products_info: "添加不需要显示在收据上的产品",
    categories_included: "包括的目录",
    categories_included_info: "加要显示在收据上的目录",
    categories_excluded: "排除的目录",
    categories_excluded_info: "添加您不想在收據上顯示的目錄",
    auto_print_on_status: "自动打印的订单状态",
    new_not_paid: "新订单(未支付)",
    new_paid: "新订单(已支付)",
    order_accepted: "订单已支付，已确认",
    order_update: "订单内容已更新",
    order_complete: "处理结束的订单",
    order_cancel: "订单被取消",
    missing_auto_print_status: "请选择自动打印状态",
    repeat_product: "产品已被选中，请先从之前的选择中删除",
    repeat_category: "类别已被选中，请先从之前的选择中删除",
    missing_selected_categories_only: "请在“仅打印所选类别”选项中选择类别或产品",
    total_included_tips: "总价已包含小费",
    surcharge_paid: "附加费(已支付)",
    surcharge_unpaid: "附加费(未支付)",
    instant_checkout: "Quick Pay 快速支付",
    surcharege_paid_message: "此订单不包括附加费。请参考订单 #{id}",
    surcharge_paid_to_message: "本订单是用来支付订单 #{id}的附加费",
    total_refunded: "退款总额",
    print_time: "打印时间",
    reprint: "重印",
    scan_qr_to_order: "扫码下单",
    referral: "推荐",
    referrals: "推荐",
    total_referrals: "总推荐数量",
    new_referrals: "新推荐数量",
    new_referral_orders: "新推荐订单",
    average_referral_orders: "平均推荐订单金额",
    referrals_count: "推荐数量",
    reward_balance: "积分余额",
    add_reward_points: "添加积分",
    redeem_reward_points: "扣除积分",
    add_rewards_points_title: "添加积分: {customer_name}",
    redeem_rewards_points_title: "扣除积分: {customer_name}",
    points_history: "积分历史",
    referral_order_history: "推荐订单历史",
    customer_referrals: "推荐的客户",
    date_time: "日期/时间",
    points_change: "积分变化",
    add_points: "添加积分",
    add_by_points: "按积分",
    add_by_value: "按金额",
    redeem_points: "提取积分",
    redeem: "提取",
    redeem_all: "全部提取",
    view_referrals_list: "查看推荐列表",
    referred_orders_amount: "推荐的订单",
    earned_points: "积分",
    min_num_ord: "最少单数",
    max_num_ord: "最多单数",
    min_ref_ord_amt: "最低推荐订单总额",
    max_ref_ord_amt: "最高推荐订单总额",
    min_points_earned: "最低积分",
    max_points_earned: "最高积分",
    referral_points: "推荐积分",
    min_points_bal: "最低积分",
    max_points_bal: "最高积分",
    min_reward_pts: "最低积分",
    max_reward_pts: "最高积分",
    reward_action_admin: "",
    reward_action_register: "",
    reward_action_newsletter: "",
    reward_action_review: "",
    reward_action_tag: "",
    reward_action_order_extra: "购买积分",
    reward_action_order: "用奖励积分支付订单",
    reward_action_invitation_customer: "推荐客户积分",
    reward_action_invitation_order: "推荐订单积分",
    reward_action_creditmemo: "商家奖励积分",
    reward_action_salesrule: "购买订单积分",
    reward_action_revert: "提取积分",
    reward_action_creditmemo_void: "推荐的订单退款",
    referral_range: "推荐范围",
referral_balance_range: "推荐余额范围",
referral_order_range: "推荐订单范围",
new_store_management: "商店管理",
signup_id: "注册ID",
admin_id: "管理员ID",
category_id: "类别ID",
rejected: "已拒绝",
need_approval: "需要批准",
    not_found_description: "抱歉！找不到页面",
    not_found_detail_description: "很抱歉，您要查找的页面不存在，已被删除，名称已更改或暂时不可用",
    back_to_home: "返回首页",
    enter_table_no: "请输入桌号",
    enter_party_size: "请输入人数",
    enter_tableno_party_size: "请输入桌号和人数",
    number_of_reviews: "查看%s条评价",
    only_have_stock_option: "选项%2$s中的%1$s只有%3$d个库存。",
    asset_statements: [
        {
            "relation": ["delegate_permission/common.handle_all_urls"],
            "target": { "namespace": "web", "site": "https://m.goopter.com" },
        },
    ],
    payment_device_name: "支付设备名",
    port_number: "端口号",
    port_number_hint: "端口号",
    kiosk_device_name: "自助下单设备名",
    "3rd_party_package_hint": "第 3 方包名",
    order_items: "Order Items",
    add_to_cart_success: "Product has been successfully added to the cart",
    add_back: "Add Back",
    reward_points_extra_text: "The points balance will be updated on your account upon order completion.",
    with_option: "With Option(s)",
    qrcode: "QRcode",
    email_required: "Email is required",
    password_required: "Password is required",
    phone_required: "Phone is required",
    vc_required: "Verification code is required",
    please_input_at_least_two_characters: "Please input at least two characters",
    google: "Google",
    wechat: "WeChat",
    apple: "Apple",
    phone_num: "Phone #",
    change_address: "Change Address",
    purchase_a_giftcard: "Purchase a Gift Card",
    self_checkout_disabled: "Quick pay is not available for this store.",
    camera_error_title: "Camera access denied",
    camera_error_description_dine_in: "Please allow camera access to scan the QR code for ordering.",
    camera_error_button_text: "Enable Camera",
    social_binding: "Social Binding",
    linked: "Linked",
    braintree_credit_card_invalid_message: "Something went wrong. Check your card details and try again.",
    your_order: "Your Order",
    clover_setting_tip: "Clover (e-commerence)",
    global_payment_setting_tip: "Global payment (Payfields)",
    account_credential: "Account credential",
    global_payment_api_key: "Global payment API key",
    api_secret: "API secret",
    duplicate_name_msg: "There is already a menu with this name. Please use a different one.",
    print_layout_on: "Print layout ON",
    print_layout_off: "Print layout OFF",
    no_admin_user_access: "Save unsuccessful: You do not have the required permissions to perform this action.",
    cloud_solution: "Cloud Solution",
    semi_integrated_solution: "Semi Integrated Solution",
    enable_special_request: "Enable Special Request",
    special_request_tip: "Turn this on to allow users to submit special requests when adding this item to the cart.",
    apiSecret: "Api Secret",
    auth_key: "Auth Key",
    config_code: "Config Code",
    dev_id: "Developer ID",
    key_id: "Key ID",
    signing_secret: "Signing Secret",
    third_party_delivery_error: "一次只能启用一个第三方配送服务。",
    third_party_field_error_message: "错误：您必须填写所有字段",
    doordash_delivery: "Doordash 配送",
    delivery_request_title: "申请配送服务",
    request_delivery_estimated_fee: "此订单的预计配送费：{estimatedDeliveryFee} + 税",
    doordash_delivery_confirmation: "配送请求已发送，将根据配送员位置和您的订单紧迫性来分配一名配送员。",
    successful_cancellation: "配送申请已被取消，将不收取任何服务费。",
    doordash_cancel_failure:
        "取消配送请求失败，请致电 855-599-7066 申请取消，并提供您的 DoorDash Drive ID：{delivery_task_id}，客户姓名：{name}，客户电话号码：{customer_phone_number}，以及您的店名。",
    doordash_cancel_failure_note: "请注意，您还需支付原配送费和税金。",
    cancellation_confirm_title: "确认取消",
    cancel_confirmation_msg: "您确定要取消此订单的配送请求吗？",
    estimated_delivery: "预计配送费用/时间",
    estimated_delivery_fee: "{price} + 税",
    delivery_fee_try_again: "无法获取运费",
    track_delivery: "跟踪配送位置",
    request_delivery_service: "申请配送服务",
    cancel_delivery: "取消配送",
    estimated_doordash_delivery_time: "送达时间 {time}",
    use_doordash: "使用 Doordash 配送",
    delivery_sent: "配送请求已经发送",
    request_cancelled: "配送请求已取消",
    both_disabled_error: "请启用其中一个第三方配送服务",
    create_delivery_request_failed: "创建配送任务失败。请稍后再试。",
    cancel_delivery_request: "取消配送请求",
    cancel_failure_subtitle_one: "请联系支持团队 ",
    cancel_failure_subtitle_two: " 请求取消。",
    cancel_failure_list_title: "请准备好提供以下信息：",
    cancel_failure_doordash_id: "Doordash ID: ",
    cancel_failure_customer_name: "客户姓名: ",
    cancel_failure_customer_phone_number: "客户电话：",
    cancel_failure_store_name: "店铺名称：",
    failed_cancel_delivery_request: "取消配送请求失败",
    default_item_name: "默认产品名称",
    default_order_value: "默认订单金额",
    default_tip_amount: "默认小费金额",
    default_pick_up_instructions: "默认取件说明",
    contains_alcohol: "含有酒精",
    timeslot_delta_title: "时间列表中的每个时间差",
    timeslot_gap_title: "时间列表中的每个元素的时间差",
    timeslot_delta_hints:
        "时间列表中每个元素的时间差。 如果值为 0，则时间段列表将是单个时间列表，如 9:00 am; 9:30 am; 10:00 am ...； 如果该值大于 0，那么它将是一个时间范围，例如，如果值为 15，则时间段列表格式将类似于 9:00 am - 9:15 am; 9:15 am - 9:30 am  ....",
    timeslot_gap_hints:
        "两个相邻时间列表记录之间的时间差。 例如 如果该值设置为 30 并且时间列表中的每个元素的时间差为 0，则时间段记录格式为: 9:00 am, 9:30 am, 10:00 am ..； 如果时间列表中的每个元素的时间差为 15，则时隙记录格式将为: 9:00 am - 9:15am; 9:30 am - 9:45 am; 10 am - 10:15 am... ",
    default_pick_up_delay_time: "默认取单延迟时间",
    pickup_delay_time_hint:
        "取单延迟时间定义了从现在开始到可以由配送员来取之前的准备时间。例如：如果该值设置为 30，则取单的默认开始时间将为当前时间 + 30 分钟。",
    pre_order_days: "可预定天数",
    create_customer_success: "成功创建客户",
    delivery_options: "配送选项",
    meet_at_door: "门口见面",
    leave_at_door: "留在门口",
    meet_outside: "在外面见面",
    delivery_instructions: "额外配送指令",
    doordash_should_send_notifications: "通过 SMS 发送配送状态通知",
    delivery_fee_credit_limit: "配送费透支信用额度",
    delivery_failed_title: "创建配送任务失败",
    error_enter_allowed_country_field: "您选择了指定国家的选项，请在保存前输入允许的国家/地区。",
    insufficient_fund_title: "配送请求的资金不足",
    insufficient_fund_msg:
        "您没有足够的资金来请求DoorDash配送。您可以电子转账至 payment@goopter.com 或付款给Goopter以继续使用DoorDash配送服务。",
    support_number: "服务电话：(778) 379-7919",
    failed_to_create_delivery_message_line1: "创建配送任务失败。",
    failed_to_create_delivery_message_line2: "请修正问题后重试。",
    failed_to_cancel_delivery_message: "取消配送失败，请从以下错误信息中查看原因。",
    failed_to_update_delivery_message: "更新配送任务失败，请从以下错误信息中查看原因。",
    input_order_value: "输入订单金额",
    input_pickup_time: "选择取单时间",
    input_delivery_time: "选择配送时间",
    order_value: "订单价值",
    mandatory_fields_for_delivery_task: "创建配送任务的必填字段",
    review_booster: "客户评价助推器",
    enable_automated_review_sms: "启用自动短信评价提醒",
    sms_review_reminder_style: "短信评价提醒风格",
    social_review_url: "发布社交媒体评价的链接",
    review_hint: "客户评价提示信息",
    review_delay_time: "发送自动评价提醒的延迟时间",
    no_duplicate_review_same_day: "每个用户每天只允许一次评论",
    message_limit_per_month: "每月总短信消息限制",
    review_booster_setting: "客户评价助推器设置",
    review_booster_setting_description:
        "客户评价助推器是为了提醒客户在与商家完成订单后给予及时的反馈，并鼓励客户在社交媒体上发布正面评价。",
    generate_google_review: "生成Google 评分的链接",

    // TOADD
    "pickUp.address": "Pick Up Address",
    "dropOff.address": "Drop Off Address",
    "dropOff.time": "Drop Off Time",
    "pickUp.time": "Pick Up Time",
    "additionalInfo.itemsInfo": "Items",
    "Meet outside": "Meet outside",
    pickup_address: "取件地址",
    dropoff_address: "送达地址",
    dropoff_time: "送达时间",
    electronic_item: "电子产品",
    sub_total: "小计",
    new_option: "添加选项",
    new_item: "添加物品",
    please_select: "请选择",
    delivery_remark_placeholer: "配送注意事项",
    delivery_option: "送货选项",
    missing: "遗漏",
    firstname: "名字",
    lastname: "姓氏",
    zipcode: "邮编",
    new_pending: "新订单",
    driver_info_label: "配送员信息",
    assigning_courier: "安排配送员中",
    confirmed: "确认",
    enroute_to_pickup: "取件途中",
    arrived_at_pickup: "到达取货点",
    picked_up: "已取件",
    enroute_to_dropoff: "派送途中",
    arrived_at_dropoff: "到达配送目的地",
    status_delivered: "完成配送",
    status_cancelled: "取消",
    cancel_task: "取消任务",
    task_detail: "任务详情",
    task_items: "配送物品",
    task_delivery_status: "配送状态",
    pick_up: "外带",
    drop_off: "送达",
    ongoing: "进行中",
    tip_amount: "小费金额",
    order_info: "订单信息",
    time_info: "时间信息",
    requested_dropoff_time: "要求送达时间",
    requested_pickup_time: "要求取件时间",
    est_pickup_time: "预计取件时间",
    est_dropoff_time: "预计送达时间",
    new_task_btn_label: "新任务",
    csv: "CSV",
    task_details: "Task Details",
    edit_order_id: "编辑订单编号",
    request_local_delivery: "请求本地配送",
    discard_task: "放弃任务",
    add_an_item: "添加产品",
    add_an_option: "添加选项",
    create_on: "创建时间",
    total_delivery_fee: "运费总额",
    total_balance: "总余额 $",
    proceed: "继续",
    TBD: "待定",
    get_delivery_fee_err: "无法计算运费：",
    confim: "确认",
    distance_error_msg: "指定地址超出配送距离，请选择其他地址。",
    new_address_error_msg: "无法添加新地址，请选择其他地址后重试。",
    cancel_create_task_msg: "您有未保存的更改，您确定要放弃创建任务吗？?",
    discard_change_msg: "您有未保存的更改，确定要放弃修改吗？",
    change_pickup_time_warning_msg:
        "您只能为取货或者送达时间指定其中一个时间。如果您提供送达时间，取货时间将更改为尽快。您要继续更改吗？",
    change_dropoff_time_warning_msg:
        "您只能为取货或者送达时间指定其中一个时间。如果您提供取件时间，送达时间将更改为尽快。您要继续更改吗？",
    remove_dropoff_time_warning_msg:
        "您只能指定取单时间或送达时间。如果您指定送达时间，则将删除取单时间。您要继续更改吗？",
    remove_pickup_time_warning_msg:
        "您只能指定取单时间或送达时间。如果您指定取单时间，则将删除送达时间。您要继续更改吗？",
    unexpected_error_msg: "意外的错误",
    tracking_url_not_found: "未找到追踪订单的链接。",
    input: "输入",
    delivery_fee_total: "运费总额",
    grocery: "百货",
    cake: "蛋糕",
    flower: "花卉",
    document: "文件",
    appliance: "家电",
    furniture: "家具",
    other: "其他",
    contact_info: "联系信息",
    search_address_placeholder: "通过电话号码或姓名搜索地址",
    create_address: "创建地址",
    succeeded_msg: "{msg_type} 成功",
    delivery_cancelled: "已取消订单: {id}",
    discard_change: "放弃修改",
    new_address: "新地址",
    search_result_msg: "搜索结果 · 找到 {records} 条记录",
    search_customer: "搜索客户",
    input_address: "输入地址",
    input_dropoff_time: "选择送达时间",
    generate_order_id: "生成订单号",
    update_address: "更改地址",
    item_option: "商品选项",
    new_item_option: "新商品选项",
    value_or_tips: "订单价值/小费",
    edit_or_add_address_info: "添加/编辑地址",
    not_authorized: "未授权",
    not_authorized_delivery_msg:
        "您尚未获得授权使用第三方本地配送的服务，请联系Goopter开通本地配送服务，邮箱：support@goopter.com，电话：778-379-7918",
    delivery_task_created: "配送任务已创建, 订单号 #{order_id}.",
    failed_to_refund: "退款失败",
    addressLineTwo: "单元号/楼层",
    buzzCode: "门铃",
    componentName: "公司/大楼名称",
    add_balance: "增加余额",
    delivery_fee_balance_update: "更改配送服务信用余额",
    current_balance: "当前信用余额",
    add_balance_action: "操作",
    add_balance_credit_label: "增加信用余额",
    add_balance_debit_label: "扣除信用余额",
    new_balance: "最新余额",
    balance_updated: "余额已更新",

    //new modified
    ppcp: "PayPal Complete Payments",
    paypal_complete_payments_paypal_with_3ds: "Credit Card",
    paypal_complete_payments_paypal: "PayPal",
    paypal_complete_payments_google_pay: "谷歌支付",
    paypal_complete_payments_apple_pay: "苹果支付",
    paypal_complete_payments_venmo: "Venmo",
    paypal_complete_payments_paylater: "PayPal (Pay Later)",
    paypal_complete_payments_wallet_credit_card: "Paypal Credit Card",
    merchant_number_error: "商家号码错误",
    merchant_number_error_msg: "商家号码必须是数字",
    ppcp_account_setting: "PayPal Complete Payments",
    ppcp_mode: "收款模式",
    save_changes_onboard: "登录PayPal账户",
    disconnect_paypal_button: "登出账户",
    ppcp_account_setting_tip:
        "PayPal Complete Payment为商家提供最先进、最全面的在线支付支持，可以让您的PayPal商业账户与Goopter电商系统无缝对接。",
    ppcp_merch_name: "商家名称",
    ppcp_merch_phone: "联系电话",
    ppcp_merchant_id: "商家ID",
    ppcp_txn_type: "支付操作",
    sale: "销售",
    partner_fee_section: "商家费用",
    add_partner_fee_rule: "增加商家费用规则",
    ppcp_payment_google_pay: "谷歌支付",
    ppcp_payment_apple_pay: "苹果支付",
    ppcp_payment_paypal: "PayPal",
    important_notice: "重要提示:",
    ensure_you: "请确保您已经遵循",
    important_notice_tips: "以保护您的交易。请注意，寄送到交易确认以外的地址可能会导致您的买家保护失效。",
    Seller_Protection_Policy: " 卖家保障政策 ",
    select_order_type: "选择订单类型",
    partner_fee_type: "商家费用类型",
    partner_fee_value: "商家费用比例/金额",
    partner_fee_based_on: "费用计算方式",
    extra_fee_tips: "额外小费",
    extra_fee_delivery: "额外配送费",
    extra_fee_surcharge: "附加费用",
    ppcp_pickup_fee: "自取",
    ppcp_instore_fee: "堂食",
    ppcp_delivery_fee: "配送",
    ppcppercentage: "百分比",
    ppcpfixed: "固定金额",
    fee_type: "商家费用类型",
    fee_value: "商家费用比例/金额",
    additional_fees_includes: "额外费用",
    partner_extra_fee_tips: "小费",
    partner_extra_fee_delivery: "配送费",
    partner_extra_fee_surcharge: "附加费用",
    confirm_delete_partner_fee: "删除商家费用规则",
    confirm_delete_partner_fee_description: "您确定要删除商家费用规则吗？",

    invalid_fee_value: "无效的费用",
    InStore: "堂食",
    enter_valid_number_for_fee_value: "请输入有效的费用数额",
    payment_data_error: "支付数据错误",
    payment_data_error_msg: "请检查您的支付数据",
    ppcp_partner_fee_type_in_store: "商家费用类型：堂食",
    ppcp_partner_fee_value_in_store: "商家费用比例/金额：堂食",
    ppcp_partner_fee_type_pickup: "商家费用类型：自取",
    ppcp_partner_fee_value_pickup: "商家费用比例/金额：自取",
    ppcp_partner_fee_type_delivery: "商家费用类型：配送",
    ppcp_partner_fee_value_delivery: "商家费用比例/金额：配送",
    select_fee_type: "选择费用类型",
    enter_fee_value: "输入费用数额",
    edit_partner_fee_rule: "编辑商家费用规则",
    select_fee_based_on: "选择费用计算方式",
    partner_fee_details: "商家费用详情",
    ppcp_partner_fee_type: "商家费用类型",
    ppcp_partner_fee_value: "商家费用比例/金额",
    ppcp_contingency_3d_secure: "3D Secure",
    ppcp_thirdparty_token: "生成第三方访问密钥",
    generate: "生成",
    revoke: "撤销",
    generated_at: "生成时间",
    paypal_complete_payments_credit_card: "信用卡",
    paypal_complete_payments: "信用卡",
    payment_validation_error: "支付验证错误",
    payment_validation_PaymentsReceivable_error: 
        "账户已被PayPal限制，请检查PayPal账户收件箱，查收来自PayPal的邮件，以确定下一步操作",
    payment_validation_PrimaryEmailConfirmed_error:
        "请确认您的PayPal账户的电子邮件地址。",
    payment_validation_OauthIntegrations_error:
        "请重新进行“开始连接到PayPal”的操作，并授予我们第三方权限。如果在此注册流程中出现任何复选框，商户需要勾选这些复选框。",
    payment_option_error: "请确保只使用了一个付款选项。",
    payment_option_error_content:
        "PayPal Complete Payments和BrainTree支付不能同时使用",
    fail_to_get_onboard_user_error_message: "获取用户信息失败，请稍后再试。",
    fail_to_get_ppcp_3rd_party_access_token_message: "获取PPCP 第三方访问密钥失败",
    fail_to_revoke_ppcp_3rd_party_access_token_message: "撤销PPCP 第三方访问令牌失败",
    partially_refunded: "部分退款",
    fully_refunded: "已退款",
    voided: "取消",
    amount_paid: "已付金额",
    reward_points_redeemed: "积分消費金额",
    gift_card_redeemed: "礼品卡消费金额",
    partial_refund_limit_description: "如果订单使用积分或礼品卡以及其他付款方式混合支付，则部分退款的最高金额不应超过支付金额。",
};
